@import "styles/vars";

div.c-orders-list {
	margin-top: 15px;
	@include transition;
	@include max($mMobileL) {
		padding: 0 15px; }
	.c-simple-input {
		margin: 0 auto; }
	> * {
		margin-bottom: 25px;
		&:last-child {
			margin-bottom: 0; } } }
