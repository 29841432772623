@import "styles/vars";

main.v-search {
	padding-left: 30px;
	padding-right: 30px;
	@include max($mMobileS) {
		padding-left: 20px;
		padding-right: 20px; }

	div.vs-content {
		padding: 20px 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		@include max($mTabletB) {
			display: block; }

		div.vs-left {
			width: 290px;
			margin-right: 30px;
			flex-grow: 0;
			flex-shrink: 0;
			@include max($mTabletB) {
				width: 100%;
				padding: 30px 25px;
				border-bottom: 1px solid rgba($colorDark, .12);
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0; }
			@include max($mMobileS) {
				padding: 30px 15px; }

			form {
				display: block;
				@include childSpaceBottom(30px);

				div.switch-n-search {
					display: flex;
					align-items: center;
					justify-content: space-between;
					@include childSpaceRight(15px);
					div.c-switch-tabs {
						flex-grow: 1;
						flex-shrink: 1;
						min-width: 0; }

					div.filters-switch {
						width: 40px;
						height: 40px;
						border-radius: 8px;
						box-shadow: inset 0 0 0 1px rgba($colorDark, .12);
						cursor: pointer;
						display: flex;
						align-items: center;
						justify-content: center;
						@include transition;
						&:hover {
							background: rgba($colorDark, .12);

							span.fs-burger {
								background: $colorDark;
								&:before, &:after {
									background: $colorDark; } } }

						&.expanded {
							span.fs-burger {
								background: rgba($colorGrey, 0) !important;
								&:before, &:after {
									left: -2px;
									right: -2px; }
								&:before {
									transform: translateY(6px) rotate(45deg); }
								&:after {
									transform: translateY(-6px) rotate(-45deg); } } }

						span.fs-burger {
							@include transition;
							height: 2px;
							background: $colorGrey;
							width: 12px;
							position: relative;
							&:before, &:after {
								content: "";
								background: $colorGrey;
								height: 2px;
								@include transition; }

							&:before {
								@include pos(absolute, -6px, -3px, -3px); }

							&:after {
								@include pos(absolute, auto, 3px, 3px, -6px); } } } }

				div.actions {
					position: sticky;
					bottom: 30px;
					@include transition;
					@include max($mTabletB) {
						position: static; }
					> * {
						width: 100%; } } } }

		div.vs-right {
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			width: 100%;
			> * {
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0; } }

			.c-additional-filters {
				@include max($mTabletB) {
					border-top-left-radius: 0;
					border-top-right-radius: 0; } }

			div.users-list {
				margin-right: -30px;
				display: flex;
				align-items: stretch;
				justify-content: flex-start;
				flex-wrap: wrap;
				--users-list-item-size: 240px;
				> * {
					min-width: 180px;
					width: var(--users-list-item-size);
					margin-right: 30px;
					margin-bottom: 30px;
					&.strict {
						flex-grow: 0;
						flex-shrink: 0; }
					@include max($mTabletB) {
						max-width: 480px; }
					@include max($mMobileL) {
						min-width: 0;
						width: calc(50% - 30px); } } } } } }
