@import "styles/vars";

.c-header-popup-trigger {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	* {
		user-select: none; } }

.c-header-popup {
	position: fixed;
	z-index: 1489;
	box-shadow: 0 5px 20px rgba(black, .12);
	background: white;
	border-radius: 8px;
	transform-origin: 50% 0%;
	@include singleRunAnimation(fadeInWithScaleY);
	&:before {
		$sizing: 10px;
		$halfSizing: $sizing / 2;
		content: "";
		width: $sizing;
		height: $sizing;
		position: absolute;
		top: $halfSizing * -1;
		right: 15px - $halfSizing;
		z-index: -2;
		background: white;
		transform: rotate(45deg); } }

.c-header-burger-content {
	@include pos(fixed, 80px, 0, 0, 0);
	@include transition;
	background: white;
	z-index: 1488;
	padding: 20px;
	transform-origin: 50% 0%;
	&.hidden {
		opacity: 0;
		pointer-events: none;
		transform: scaleY(.84) translateZ(0); }

	.auth-actions {
		justify-content: center; }

	.ah-router {
		margin: 0 auto;
		margin-top: 20px;
		max-width: 320px;
		&:first-child {
			margin-top: 0; }
		.route {
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid rgba($colorDark, .12);
			position: relative;
			@include transition;
			&:last-child {
				border-bottom: none; }

			&:before {
				content: "";
				@include pos(absolute, 0, 0, 0, 0);
				background: linear-gradient(to left, rgba(white, 0) 0%, rgba($colorMain, 1) 50%, rgba(white, 0) 100%);
				opacity: 0;
				@include transition(opacity); }

			&.active {
				color: $colorMain;
				font-weight: 700;
				&:before {
					opacity: .24; } } } } }


header.c-app-header {
	@include pos(fixed, 0, 0, 0);
	z-index: 1200;
	height: 80px;
	width: 100%;
	padding: 0 30px;
	background: white;
	border-bottom: 1px solid rgba($colorDark, .09);
	@include transition;
	@include max($mMobileL) {
		padding: 0 20px 0 10px; }
	&.hidden {
		opacity: 0;
		transform: translateY(-100%) translateZ(0); }

	div.ah-inner-wrapper {
		margin: 0 auto;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		div.ah-left, div.ah-right {
			display: flex;
			align-items: center;
			justify-content: flex-start; }

		div.toggle-menu {
			width: 30px;
			height: 30px;
			margin-right: 46px;
			padding: 9px 8px;
			cursor: pointer;

			&:not(.shown):hover {
				span.element {
					width: 10px;
					&:nth-child(2) {
						width: 14px; } } }
			&.shown {
				span {
					&:first-child {
						transform: rotateZ(45deg) translateY(4px) translateX(3px); }
					&:nth-child(2) {
						opacity: 0; }
					&:last-child {
						transform: rotateZ(-45deg) translateY(-4px) translateX(3px); } } }

			span.element {
				display: block;
				width: 14px;
				height: 2px;
				border-radius: 1px;
				background: $colorGrey;
				margin-bottom: 3px;
				margin-left: auto;
				@include transition;
				&:last-child {
					margin-bottom: 0; }
				&:nth-child(2) {
					width: 10px; } } }

		div.ah-right {
			@include childSpaceRight(10px);

			div.c-user-menu-button, div.c-notifications-button, div.toggle-menu {
				flex-grow: 0;
				flex-shrink: 0; }

			div.c-searchbox {
				width: calc(100vw / 3);
				margin-right: 30px !important;
				@include max($mMobileL) {
					width: 100%;
					flex-grow: 1;
					flex-shrink: 1;
					min-width: 0;
					margin-right: 10px !important; }
				&:last-child {
					margin-right: 0px !important; } } }

		div.ah-router {
			display: flex;
			align-items: stretch;
			justify-content: center;
			flex-grow: 0;
			flex-shrink: 0;
			height: 100%;

			.route {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 15px;
				cursor: pointer;
				position: relative;
				&.active {
					&:after {
						transform: scaleY(1) translateZ(0); }
					span.caption {
						color: $colorMain; } }

				&:after {
					content: "";
					@include pos(absolute, auto, 0, 0, 0);
					height: 4px;
					background: $colorMain;
					transform: scaleY(0) translateZ(0);
					transform-origin: 50% 100%;
					@include transition; }

				&:hover {
					span.caption {
						color: $colorMain; } }
				> * {
					margin-right: 25px;
					&:last-child {
						margin-right: 0; } }
				span.caption {
					@include transition;
					color: rgba($colorDark, .54); } } } } }
