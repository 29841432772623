@import "styles/vars";

div.c-user-card.horizontal {
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;

	div.uc-avatar-wrapper {
		width: 200px;
		flex-grow: 0;
		flex-shrink: 0; }

	div.uc-content {
		min-width: 0;
		max-height: 256px;
		@include max($mTabletB) {
			max-height: 100%; }
		> * {
			margin-bottom: 10px;
			@include max($mMobileL) {
				margin-bottom: 8px; }
			&:last-child {
				margin-bottom: 0; } } } }
