@import "styles/vars";

div.c-user-reviews-intro {
	div.uri-content {
		> * {
			padding: 30px 0;
			border-bottom: 1px solid rgba(black, .08);
			&:first-child {
				padding-top: 20px; }
			&:last-child {
				border-bottom: none;
				padding-bottom: 0; } } } }
