@import "styles/vars";

div.c-video-edit {
	max-width: 600px;
	padding: 40px 30px !important;

	div.ve-actions {
		&:not(.loading) {
			justify-content: flex-end; }
		&.loading {
			justify-content: center; } }

	header {
		margin-bottom: 40px; }

	div.ve-video-metadata {
		background: rgba($colorDark, .06);
		box-shadow: inset 0 0 0 1px rgba($colorDark, .12);
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 6px;
		overflow: hidden;
		> div:first-child {
			height: 100%; }

		.c-video {
			border-radius: 0;
			.c-smooth-image {
				background: rgba($colorDark, .12); } }

		div.name-size {
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			padding: 0 20px;

			p.name {
				font-weight: 700; }
			span.size {
				font-size: 14px;
				font-weight: 700;
				opacity: .54; } } }

	h3 {
		margin: 20px 0 10px 0;
		font-size: 24px; }

	div.ve-edit-cover {
		div.new-cover {
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			.c-smooth-image {
				height: 320px; }
			div.reset {
				@include pos(absolute, auto, 0, 0, 0);
				background: linear-gradient(to top, $colorDark, rgba($colorDark, 0));
				padding: 40px 0 10px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				i {
					color: white;
					cursor: pointer;
					width: 30px;
					height: 30px;
					border-radius: 6px;
					@include transition(background);
					display: flex;
					align-items: center;
					justify-content: center;
					&:hover {
						background: rgba(white, .24); } } } }


		p.recommendations {
			margin-top: 10px;
			color: rgba($colorDark, .54);
			text-align: center;
			font-size: 14px;
			strong {
				color: $colorMain; } } }

	div.ve-edit-name {
		margin-bottom: 40px;
		div.input-wrapper {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			.c-simple-input {
				flex-grow: 1;
				flex-shrink: 1;
				min-width: 0;
				& + span {
					font-weight: 700;
					line-height: 55px;
					margin-left: 5px; } } } } }
