@import "styles/vars";

@keyframes textBlink {
	0% {
		background-position-x: 100%; }
	100% {
		background-position-x: -100%; } }


div.c-text-placeholder {
	background-color: rgba($colorDark, .12);
	border-radius: 6px;
	background-image: linear-gradient(to right, rgba(white, 0) 35%, rgba(white, .54) 50%, rgba(white, 0) 65%);
	background-size: 200% 100%;
	@include singleRunAnimation(textBlink, 1200ms, linear);
	animation-iteration-count: infinite; }
