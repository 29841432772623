@import "styles/vars";

div.c-notifications-button {
	position: relative;
	&.ring {
		i {
			@include singleRunAnimation(ring, 360ms); } }

	i {
		color: $colorGrey;
		font-size: 20px; }

	span.nb-counter {
		@include pos(absolute, auto, auto, -10px, -10px);
		min-width: 20px;
		height: 20px;
		padding: 0 5px;
		border-radius: 10px;
		font-weight: 700;
		text-align: center;
		font-size: 13px;
		color: white;
		background: $colorNegative;
		transition: all $transitionTime $tjelly;
		&.hidden {
			opacity: 0;
			transform: scale(0) translateZ(0); } } }

div.c-notifications-popup {
	width: 360px;
	height: 480px;
	padding: 20px;
	@include max($mMobileL) {
		width: 300px;
		right: 10px !important;
		&:before {
			right: 100px !important; } } }
