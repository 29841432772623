@import "styles/vars";

.c-logo {
	display: block;
	color: $colorMain;
	@include max($mMobileL) {
		margin-right: 10px; }

	img {
		display: block;
		width: 60px; } }
