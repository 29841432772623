@import "styles/vars";

.c-user-profile-section-modal {
	div.upsm-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		div.u-button {
			width: 50px;
			height: 50px;
			div.ub-inner-wrapper {
				padding: 0; }
			i {
				color: black;
				font-size: 24px; } } } }

main.v-user-profile {
	div.up-section-summary {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 700;

		div.count {
			opacity: .54; }

		div.actions {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			.u-link {
				line-height: 25px; }
			.u-delimiter {
				&:first-child, &:last-child {
					display: none; } } } }

	div.up-content-wrapper {
		max-width: 1120px;
		margin: 0 auto;
		padding: 20px;

		div.c-breadcrumbs {
			margin-bottom: 20px; }

		div.up-content {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			@include max($mTabletB) {
				display: block; }
			div.left {
				flex-grow: 0;
				flex-shrink: 0;
				width: 256px;
				margin-right: 32px;
				@include max($mTabletB) {
					width: 100%;
					border-radius: 8px;
					margin-bottom: 32px; }
				@include max($mMobileL) {
					display: block;
					background: transparent;
					padding-right: 0; }

				div.up-customer-actions, div.up-own-actions {
					margin-top: 32px;
					@include max($mMobileL) {
						margin-top: 15px; } }

				div.up-customer-actions {
					.u-button + div {
						text-align: center;
						margin-top: 20px;
						span.u-link {
							font-size: 16px;
							font-weight: 700; } } }

				div.up-own-actions {
					@include max($mTabletB) {
						flex-grow: 1;
						flex-shrink: 1; }
					div.u-paper {
						padding: 0;
						overflow: hidden;
						@include childSpaceBottom(1px);
						> * {
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							position: relative;
							height: 55px;
							font-size: 14px;
							font-weight: 700;
							color: $colorMain;
							cursor: pointer;
							@include transition(background);
							&:before {
								content: "";
								@include pos(absolute, -1px, 25px, 25px);
								@include transition(background, left, right);
								height: 1px;
								background: rgba(black, .06); }

							&:first-child {
								&:before {
									content: none; } }

							&:hover {
								background: rgba($colorMain, .12);
								&:before, & + *:before {
									background: rgba($colorMain, .24);
									left: 0;
									right: 0; } } } } } }

			div.right {
				flex-grow: 1;
				flex-shrink: 1;
				min-width: 0;
				> * {
					margin-bottom: 32px;
					&:last-child {
						margin-bottom: 0; } }

				.u-paper {
					> * {
						margin-bottom: 15px;
						&:last-child {
							margin-bottom: 0; } } }

				p.main-text {
					font-size: 16px;
					line-height: 32px; }

				h2 {
					font-size: 24px;
					line-height: 36px;
					@include max($mMobileL) {
						font-size: 16px;
						line-height: 32px; } } } } } }

