@import "styles/vars";

div.c-simple-input {
	div.si-input-wrapper {
		position: relative;

		div.end-decoration, i.password-visibility-toggler {
			@include pos(absolute, 0, auto, 0);
			width: 55px;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center; }

		div.end-decoration {
			z-index: 10;
			width: auto;
			min-width: 55px;
			padding: 0 10px; }

		i.password-visibility-toggler {
			color: $colorDark;
			border-radius: 8px;
			font-size: 18px;
			opacity: .24;
			cursor: pointer;
			@include transition;
			&:hover {
				opacity: .54; } } } }
