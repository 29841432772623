@import "styles/vars";

div.c-rate {
	div.stars {
		display: flex;
		align-items: center;
		justify-items: center;
		&.rate-placed {
			i {
				cursor: default; }
			i.filled {
				color: $colorGold; } }
		i {
			width: 48px;
			height: 48px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 36px;
			color: rgba($colorDark, .12);
			cursor: pointer;
			@include transition;
			&.filled {
				color: rgba($colorGold, .72); } } } }
