@import "styles/vars";

div.c-linear-tabs {
	position: relative;
	@include max($mMobileL) {
		max-width: 100%;
		overflow-y: scroll; }
	&:after {
		content: "";
		background: rgba($colorDark, .12);
		height: 1px;
		@include pos(absolute, auto, 0, 0, 0);
		@include max($mMobileL) {
			content: none; } }

	div.tabs {
		height: 40px;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;

		.tab {
			@include transition;
			cursor: pointer;
			padding: 0 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: 700;
			color: rgba($colorDark, .38);
			@include max($mMobileL) {
				width: 100%;
				position: relative; }
			&:hover {
				color: rgba($colorMain, .54); }
			&.active {
				color: $colorMain; }
			&:after {
				background: rgba($colorDark, .12);
				height: 1px;
				@include pos(absolute, auto, 0, 0, 0);
				@include max($mMobileL) {
					content: ""; } } } }

	div.highlighter {
		@include transition;
		height: 2px;
		background: $colorMain;
		pointer-events: none;
		position: absolute;
		z-index: 2;
		left: 0;
		bottom: 0; } }
