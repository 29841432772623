@import "styles/vars";

div.c-not-verified {
	@include pos(fixed, auto, 0, 0, 0);

	div.nv-card {
		@include transition;
		@include pos(absolute, auto, auto, 50%, 40px);
		transform: translateX(50%) translateZ(0);
		background: #FEF4CF;
		box-shadow: inset 0 0 0 2px $colorWarning, 0 9px 24px rgba(black, .12);
		height: 80px;
		border-radius: 8px;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		width: 720px;
		@include max($mTabletB) {
			width: calc(100% - 60px); }
		@include max($mMobileL) {
			width: calc(100% - 20px);
			padding: 10px; }
		@include max($mMobileM) {
			height: 100px; }

		&.shifted {
			transform: translateX(50%) translateY(120px) translateZ(0);
			opacity: 0; }

		&.hidden {
			cursor: pointer;
			right: 20px;
			bottom: 20px;
			transform: translateZ(0);
			width: 80px;
			@include max($mMobileL) {
				width: 60px;
				height: 60px; }
			div.nv-text, div.nv-actions {
				opacity: 0;
				transition: none; } }

		&:not(.hidden) {
			> i.fas {
				@include max($mMobileM) {
					display: none; } } }

		> i.fas {
			font-size: 30px;
			margin-left: 10px;
			margin-right: 25px;
			color: $colorWarning;
			flex-grow: 0;
			flex-shrink: 0;
			@include max($mMobileL) {
				font-size: 27px;
				margin-left: 5px;
				margin-right: 15px; } }

		div.nv-text {
			@include transition(opacity);
			transition-delay: $transitionTime;
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			@include childSpaceBottom(5px);
			@include max($mMobileL) {
				font-size: 12px; }
			span {
				opacity: .54; } }


		div.nv-actions {
			@include transition(opacity);
			transition-delay: $transitionTime;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 25px;
			@include max($mMobileL) {
				margin-left: 15px; }

			.nv-verify {
				margin-right: 10px;
				min-width: 60px;
				max-width: 120px;
				@include max($mTabletB) {
					margin-right: 5px; }
				@include max($mMobileL) {
					div.ub-inner-wrapper {
						font-size: 12px; } } }

			.nv-close {
				width: 40px;
				color: black;
				flex-grow: 0;
				flex-shrink: 0;
				i {
					font-size: 16px; } } } } }
