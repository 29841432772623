@import "styles/vars";

html, body {
	position: relative;
	height: 100%;
	overscroll-behavior-y: contain; }

body {
	margin: 0;
	padding: 0;
	font-family: $fontMain;
	font-size: 16px;
	overflow-y: scroll;
	color: $colorDark;
	&.usnone {
		user-select: none; }
	&.dragged {
		@extend .usnone;
		cursor: grabbing !important;
		div#__root {
			* {
				pointer-events: none; } } }
	* {
		color: inherit;
		font-size: inherit;
		font-family: inherit;
		margin: 0;
		padding: 0;
		appearance: none;
		background: transparent;
		border-radius: 0;
		box-sizing: border-box;
		border: none;
		outline: none;
		text-decoration: none;
		&:invalid {
			box-shadow: none; }
		&:before, &:after {
			box-sizing: border-box; } } }

img {
	object-fit: cover;
	object-position: center; }

main {
	padding-top: 80px;
	min-height: calc(100vh - 230px);
	@include max($mMobileL) {
		min-height: calc(100vh - 295px); } }

h1, h2, h3, h4, h5, h6 {
	font-family: $fontHead;
	font-weight: 600; }

h1 {
	font-size: 40px;
	@include max($mTabletB) {
		font-size: 32px; }
	@include max($mMobileL) {
		font-size: 24px; } }
h2 {
	font-size: 36px;
	@include max($mMobileL) {
		font-size: 20px; } }
h3 {
	font-size: 30px; }
h4 {
	font-size: 24px; }
h5 {
	font-size: 20px; }
h6 {
	font-size: 18px; }

.sol__tooltip.fade-out {
	@include singleRunAnimation(fadeOut, 250ms, $transition); }
