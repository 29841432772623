@import "styles/vars";

.u-fade-in, .u-fade-out {
	animation-duration: 240ms;
	animation-timing-function: $transition;
	animation-fill-mode: both; }

.u-fade-in {
	animation-name: fadeIn; }

.u-fade-out {
	animation-name: fadeOut; }

.u-fs-view {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;
	h1 {
		margin-bottom: 30px; }
	> * {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0; } } }

.u-button {
	height: 65px;
	overflow: hidden;
	border-radius: 8px;
	color: white;
	cursor: pointer;
	display: block;
	@include transition;
	div.ub-inner-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 10px 40px;
		transition: background 240ms $transition;
		background: transparent;
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		@include max($mMobileL) {
			font-size: 14px; }
		> * {
			margin-right: 10px;
			&:last-child {
				margin-right: 0; } } }
	&:not(.disabled):not(:disabled):not(.outline):not(.nohover):hover {
		div.ub-inner-wrapper {
			background: rgba(black, .24); } }

	&:not(.smaller):not(.x-small) {
		@include max($mMobileL) {
			height: 55px; } }

	&.smaller {
		height: 55px;
		@include max($mMobileL) {
			height: 40px; }
		div.ub-inner-wrapper {
			padding: 10px 30px; } }

	&.x-small {
		height: 40px;
		div.ub-inner-wrapper {
			padding: 0 10px;
			font-size: 14px; } }

	&.default {
		background: $colorMain;
		&-invert {
			color: $colorMain;
			background: rgba($colorMain, .12);
			&:not(.disabled):not(:disabled):not(.nohover):hover {
				div.ub-inner-wrapper {
					background: rgba($colorMain, .12) !important; } } } }

	&.green {
		background: $colorPositive;
		&-invert {
			color: $colorPositive;
			background: rgba($colorPositive, .12);
			&:not(.disabled):not(:disabled):not(.nohover):hover {
				div.ub-inner-wrapper {
					background: rgba($colorPositive, .12) !important; } } } }

	&.red {
		background: $colorNegative; }

	&.disabled, &:disabled {
		opacity: .38;
		filter: grayscale(1);
		cursor: not-allowed; }

	&.outline {
		background: rgba($colorMain, 0);
		box-shadow: inset 0 0 0 1px rgba($colorDark, .09);
		color: rgba($colorDark, .54);
		div.ub-inner-wrapper {
			font-weight: 400; }
		&.bright {
			box-shadow: inset 0 0 0 1px $colorMain;
			color: $colorMain;
			&:not(.nohover):hover {
				box-shadow: inset 0 0 0 2px $colorMain; } }
		&:not(.nohover):hover {
			background: rgba($colorMain, .12);
			box-shadow: inset 0 0 0 2px rgba($colorMain, .38);
			color: $colorMain; } } }

.u-actions {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	&.justify-end {
		justify-content: flex-end; }

	&.column {
		flex-direction: column;
		justify-content: flex-start;
		@include childSpaceBottom(25px); }

	&:not(.column) {
		@include childSpaceRight(10px); }

	&.auto-size {
		> *:not(.shrink) {
			width: 100%; }
		> .shrink {
			flex-grow: 0;
			flex-shrink: 0; }
		> .no-stretch {
			align-self: center; } }

	> * {
		margin-right: 10px;
		&:last-child {
			margin-right: 0; } } }

.u-dimmed {
	opacity: .54;
	&-text {
		color: rgba($colorDark, .54); } }

.u-input-label {
	line-height: 25px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: 14px;
	color: rgba($colorDark, .38);
	margin-bottom: 5px;
	@include transition;
	&.required {
		padding-right: 40px; }

	span.required-dot {
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: rgba($colorAccent, .38);
		@include pos(absolute, 5px, auto, 10px); }

	.invalid & {
		color: $colorNegative; } }

.u-input {
	display: block;
	width: 100%;
	height: 55px;
	padding: 0 15px;
	border-radius: 8px;
	background: rgba($colorDark, .03);
	@include transition;

	@include placeholder {
		color: rgba($colorDark, .38); }

	&:focus, &.focused, &.filled {
		box-shadow: inset 0 0 0 1px rgba($colorDark, .06); }

	.invalid & {
		box-shadow: inset 0 0 0 1px $colorNegative; }
	&:read-only {
		cursor: not-allowed; } }

input.u-input {
	@include placeholder {
		color: rgba($colorDark, .38); } }

textarea.u-input {
	padding: 15px;
	line-height: 25px;
	resize: vertical;
	min-height: 110px;
	transition-property: background, box-shadow;
	@include scrollbar($colorDark, 0, .54); }

.u-invalidation-message {
	font-size: 12px;
	color: $colorNegative;
	@include transition;
	line-height: 0px;
	opacity: 0;
	padding: 0 15px;

	.invalid &, &.independent.visible {
		padding-top: 3px;
		line-height: 18px;
		opacity: 1; }

	&.independent:not(.visible) {
		margin-top: 0; }

	&.center {
		text-align: center; } }

.u-link {
	color: $colorMain;
	position: relative;
	cursor: pointer;
	@include transition;
	@include childSpaceRight(8px);

	&.dimmed {
		color: rgba($colorDark, .54); }

	&:after {
		content: "";
		@include pos(absolute, auto, 0, 0, 0);
		height: 1px;
		background: $colorMain;
		opacity: 0;
		transform: scaleX(0) translateZ(0);
		@include transition; }

	&:hover {
		opacity: .72;
		&.dimmed {
			opacity: 1;
			color: $colorMain; }
		&:after {
			opacity: 1;
			transform: scaleX(1) translateZ(0); } } }

.u-checkbox {
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	box-shadow: inset 0 0 0 1px rgba($colorDark, .09);
	@include transition;
	i {
		opacity: 0;
		transform: rotate(-45deg) translateZ(0);
		@include transition;
		color: white;
		font-size: 12px; }

	&.checked {
		box-shadow: inset 0 0 0 20px $colorMain;
		i {
			opacity: 1;
			transform: translateZ(0); } } }

.u-dib {
	display: inline-block; }

.u-range {
	width: 100%;
	position: relative;
	height: 4px;
	margin-top: 20px;
	margin-bottom: 10px;
	div.track {
		border-radius: 2px;
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:not(.active) {
			background: rgba($colorDark, .12);
			left: 0;
			right: 0; }
		&.active {
			background: $colorMain; }

		div.delimiter {
			width: 4px;
			height: 4px;
			background: rgba($colorDark, .38);
			border-radius: 50%; } }

	div.thumb {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: $colorMain;
		position: absolute;
		top: -6px;
		cursor: pointer;
		@include transition;
		transition-property: transform, box-shadow;
		&:hover {
			transform: scale(1.25) translateZ(0); }
		&.dragged {
			transform: scale(1.5) translateZ(0);
			box-shadow: 0 0 1px rgba(black, .12) 0 3px 6px rgba(black, .24); } } }

.u-paper {
	background: white;
	border-radius: 8px;
	padding: 30px;
	&.no-padding {
		padding: 0; } }

.u-square {
	width: 100%;
	padding-top: 100%;
	position: relative;
	> * {
		@include pos(absolute, 0, 0, 0, 0); } }

main.u-grey-view {
	background: $colorBackground; }

.u-toe {
	text-overflow: ellipsis;
	overflow: hidden;
	&:not(.wrap) {
		white-space: nowrap; } }

.u-center {
	display: flex;
	align-items: center;
	justify-content: center; }

.u-color {
	&.negative {
		color: $colorNegative; } }

.u-decorate {
	&.underline {
		text-decoration: underline; } }

.u-transparent {
	opacity: 0;
	pointer-events: none; }

.u-not-found-message {
	font-size: 20px;
	text-align: center; }

.u-external-link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 15px;
	border-radius: 8px;
	box-shadow: inset 0 0 0 1px rgba($colorDark, .12);
	@include transition;
	&:hover {
		color: $colorMain;
		box-shadow: inset 0 0 0 2px rgba($colorMain, .24);
		background: rgba($colorMain, .12); }

	> i {
		margin-right: 10px;
		opacity: .54; }

	> span {
		font-size: 14px; } }

.u-delimiter {
	margin: 0 15px;
	width: 1px;
	background: rgba($colorDark, .12); }

.u-recaptcha-disclaimer {
	font-size: 12px;
	text-align: center;
	color: rgba($colorDark, .54);
	a {
		display: inline-block; } }
