@import "styles/vars";

main.v-account-order-item {
	div.vaoi-inner-wrapper {
		padding: 25px 40px;
		max-width: 1120px;
		margin: 0 auto;
		@include max($mMobileL) {
			padding: 25px 15px; }

		div.vaoi-subactions {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 25px; } } }
