@import "styles/vars";

@keyframes imageLining {
	0% {
		stroke-dasharray: 0 100;
		stroke-dashoffset: 0;
		opacity: 1; }
	24% {
		stroke-dasharray: 100 0;
		opacity: 1; }
	100% {
		stroke-dasharray: 100 0;
		opacity: 0; } }


div.c-image-preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		width: 100%;
		max-width: 50px;
		polyline {
			animation-name: imageLining;
			animation-duration: 2400ms;
			animation-timing-function: ease;
			animation-fill-mode: both;
			animation-iteration-count: infinite; } } }
