@import "styles/vars";

main.v-sign-up {
	min-height: 100vh;
	padding: 40px;
	position: relative;
	display: flex;
	align-items: stretch;
	justify-content: flex-start;
	@include max($mMobileL) {
		padding: 15px; }

	section.side-image {
		@include pos(absolute, 40px, 50%, 40px, 40px);
		background: rgba($colorDark, .06);
		border-radius: 40px;
		@include max($mTabletA) {
			display: none; } }

	section.main-content {
		width: 100%;
		max-width: 1200px;
		margin: 0 auto;
		min-height: 100%;
		display: flex;
		align-items: stretch;
		justify-content: flex-start;
		> * {
			min-width: 0; }

		div.main-content-inner-wrapper {
			width: 50%;
			padding-right: 160px;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: space-between;
			@include max($mDesktopS) {
				padding-right: 80px; }
			@include max($mTabletA) {
				padding-right: 0;
				width: 100%; }

			> * {
				min-width: 0; }

			header, footer {
				display: flex;
				align-items: center;
				justify-content: space-between; }

			div.section-content {
				padding: 25px 0;
				@include max($mTabletA) {
					max-width: 600px;
					margin: 0 auto; }
				@include max($mMobileL) {
					width: 100%; }
				&.fade {
					&-in {
						@include singleRunAnimation(shiftInX);
						&.backward {
							@include singleRunAnimation(shiftInXReverse); } }

					&-out {
						@include singleRunAnimation(shiftOutX);
						&.backward {
							@include singleRunAnimation(shiftOutXReverse); } } }

				h1 {
					@include max($mTabletA) {
						text-align: center; } }

				form.su-step {
					margin-top: 30px;
					> * {
						margin-top: 15px;
						&.closer {
							margin-top: 8px; } } }

				div.radio {
					margin-top: 25px;
					div.radio-item {
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 15px;
						margin-bottom: 15px;
						box-shadow: inset 0 0 0 1px rgba($colorDark, .09);
						border-radius: 8px;
						cursor: pointer;
						user-select: none;
						@include transition;
						&:last-child {
							margin-bottom: 0; }
						&:not(.selected):hover {
							box-shadow: inset 0 0 0 2px rgba($colorMain, .38); }
						&.selected {
							box-shadow: inset 0 0 0 2px $colorMain; }

						div.image-wrapper {
							width: 48px;
							height: 48px;
							border-radius: 4px;
							margin-right: 15px;
							overflow: hidden;
							flex-grow: 0;
							flex-shrink: 0;
							@include max($mMobileL) {
								width: 72px;
								height: 72px; }
							@include max($mMobileS) {
								width: 60px;
								height: 60px; } }

						div.text-content {
							flex-grow: 1;
							flex-shrink: 1;
							min-width: 0;
							font-size: 14px;
							p.type-name {
								font-weight: 700; }

							p.type-description {
								margin-top: 5px;
								@include toe;
								@include max($mMobileL) {
									white-space: normal;
									line-height: 24px; } } }

						div.u-checkbox {
							margin: 0 10px;
							flex-grow: 0;
							flex-shrink: 0;
							&:first-child {
								margin-right: 25px; }
							@include max($mMobileL) {
								&:not(:first-child) {
									align-self: flex-start;
									margin-right: 0; } } } } }

				div.u-actions {
					&:not(.su-default) {
						margin-top: 65px;
						margin-bottom: 25px;
						@include max($mMobileL) {
							margin-top: 20px;
							margin-bottom: 20px; } }

					& + .u-dimmed-text {
						text-align: center;
						margin-bottom: 25px; } } } } } }

