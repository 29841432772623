@import "styles/vars";

div.c-info {
	position: relative;

	> i {
		@include transition;
		font-size: 24px;
		display: block;
		cursor: pointer;
		color: $colorDark;
		opacity: .38;
		&:hover {
			opacity: .54;
			& + div.popup {
				opacity: 1;
				transform: scaleY(1) translateX(-50%) translateZ(0);
				&.shifted {
					transform: scaleY(1) translateZ(0); } } } }

	> div.popup {
		@include transition;
		position: absolute;
		width: 320px;
		background: white;
		padding: 15px;
		border-radius: 8px;
		filter: drop-shadow(0 0 1px rgba($colorDark, .04)) drop-shadow(0 4px 10px rgba($colorDark, .07));
		left: 50%;
		pointer-events: none;
		opacity: 0;
		transform: scaleY(.96) translateX(-50%) translateZ(0);
		font-size: 14px;
		line-height: 24px;
		&:before {
			content: "";
			width: 12px;
			height: 12px;
			position: absolute;
			left: calc(50% - 6px);
			transform: rotate(-45deg) translateZ(0);
			background: white; }
		&.shifted {
			left: auto;
			right: -15px;
			transform: scaleY(.96) translateZ(0);
			&:before {
				left: auto;
				right: 21px; } }
		&.top {
			bottom: calc(100% + 10px);
			transform-origin: 50% 100%;
			&:before {
				bottom: -6px; } }
		&.bottom {
			top: calc(100% + 10px);
			transform-origin: 50% 0%;
			&:before {
				top: -6px; } } } }
