@import "styles/vars";

.c-login-modal {
	max-width: 450px;
	&.error {
		@include singleRunAnimation(shakeX, 720ms);
		div.lm-content {
			p.u-invalidation-message.independent {
				> span {
					@include singleRunAnimation(shakeX, 720ms); } } } }

	div.lm-content {
		animation-delay: 120ms;
		@include childSpaceBottom(30px);

		p.u-invalidation-message.independent {
			transform: rotateY(-180deg) translateZ(0);
			> span {
				display: inline-block;
				> span {
					display: inline-block;
					transform: rotateY(-180deg) translateZ(0); } } }

		span.lm-forgot-password {
			@include transition(color);
			cursor: pointer;
			font-size: 12px;
			&:hover {
				color: $colorMain; } }

		h1 + p.u-dimmed {
			line-height: 32px; }

		form.login-form {
			> * {
				margin-top: 15px;
				&:first-child {
					margin-top: 0; }
				&.u-actions {
					margin-top: 40px; } }

			.u-dimmed-text {
				text-align: center; } } } }
