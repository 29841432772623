@import "styles/vars";

div.c-user-card.vertical {
	overflow: hidden;

	div.uc-content {
		> * {
			margin-top: 10px;
			&:first-child {
				margin-top: 0; } } } }
