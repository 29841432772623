@import "styles/vars";

.c-account-settings {

	div.two-sided-layout {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include max($mMobileL) {
			display: block; }

		> div.avatar-wrapper {
			width: 256px;
			margin-right: 30px;
			flex-grow: 0;
			flex-shrink: 0;
			@include max($mMobileL) {
				width: 100%;
				margin-bottom: 15px; }

			div.c-avatar-management {
				@include pos(absolute, 0, 0, 0, 0);
				overflow: hidden;
				border-radius: 8px;
				// img
				// 	width: 100%
				// 	height: 100%
				// 	display: block
				div.actions-overlay {
					@include pos(absolute, auto, 0, 0, 0);
					z-index: 12;
					height: 100px;
					padding-top: 40px;
					background: linear-gradient(to top, black, rgba(black, 0));
					color: white;
					display: flex;
					align-items: center;
					justify-content: center;
					@include childSpaceRight(10px);
					i {
						width: 30px;
						height: 30px;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						@include transition;
						border-radius: 4px;
						&:hover {
							background: $colorMain;
							&.fa-trash-alt {
								background: $colorNegative; } } } } } }

		> div.rest-form {
			min-width: 0;
			flex-grow: 1;
			flex-shrink: 1;
			> * {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0; } } } } }
