@import "styles/vars";

div.c-email-verification-modal {
	max-width: 450px;

	div.ev-content {
		@include childSpaceBottom(30px);

		form {
			@include childSpaceBottom(30px); }

		p {
			color: rgba($colorDark, .54);
			strong {
				color: $colorMain; } } } }
