@import "styles/vars";

div.c-user-all-reviews {
	padding: 30px !important;
	max-width: 840px;

	div.uar-content {
		@include transition(opacity);
		&.disabled {
			opacity: .38;
			& + div.uar-content {
				cursor: not-allowed; } }

		> * {
			padding: 30px 0;
			border-bottom: 1px solid rgba(black, .08);
			&:last-child {
				border-bottom: none; } } } }
