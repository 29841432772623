@import "styles/vars";

a.c-back {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $colorDark;
	&:hover {
		i, span {
			opacity: .54; }
		i {
			transform: translateX(-10px) translateZ(0); } }

	i, span {
		@include transition;
		opacity: .38; }
	i {
		margin-right: 20px; } }
