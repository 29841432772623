@import "styles/vars";

div.c-simple-modal {
	@include pos(fixed, 0, 0, 0, 0);
	background: rgba(black, .60);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10000;

	div.sm-outer-wrapper {
		width: 100%;
		max-height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
		@include scrollbar(white, 0, .72);
		padding: 40px 20px;
		@include max($mMobileL) {
			padding: 30px 10px; }

		div.sm-inner-wrapper {
			background: white;
			border-radius: 16px;
			box-shadow: 0 10px 20px rgba(black, 0.08), 0 10px 30px rgba(black, 0.08);
			padding: 65px 50px;
			margin: 0 auto;

			h1 {
				font-size: 32px;
				line-height: 48px; } } } }
