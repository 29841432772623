@import "styles/vars";

section.c-video-grid {
	div.cvg-row {
		display: flex;
		gap: 15px;
		margin-bottom: 15px;
		> * {
			flex-grow: 0;
			flex-shrink: 0; }

		div.temp-item {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: rgba(black, .54);
			text-align: center;
			overflow: hidden;

			span {
				margin-top: 15px;
				font-size: 12px;
				line-height: 15px;
				display: block; } } } }
