@import "styles/vars";

div.c-stepper {
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		width: 8px;
		height: 8px;
		border-radius: 4px;
		background: rgba($colorDark, .24);
		@include transition;
		margin-right: 16px;

		&:last-child {
			margin-right: 0; }
		&.current {
			width: 16px;
			background: $colorMain; } } }
