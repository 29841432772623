@import "styles/vars";

section.c-homepage-main {
	height: 520px;
	background: $colorDark;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	@include max($mTabletA) {
		padding: 0 35px; }
	@include max($mMobileL) {
		padding: 0 20px;
		height: 360px;
		display: block;
		padding-top: 80px; }

	div.hm-background-wrapper {
		@include pos(absolute, 0, 0, 0, 0);
		overflow: hidden;
		pointer-events: none;
		img {
			width: 100%;
			height: 100%;
			opacity: 0;
			display: block;
			&.animate {
				@include singleRunAnimation(fadeInWithScaleOut, 1200ms); } } }

	div.hm-content {
		color: white;
		position: relative;
		@include max($mMobileL) {
			text-align: center;
			width: 280px;
			margin: 0 auto; }

		h1 {
			font-weight: 700;
			margin-bottom: 15px; }

		p {
			font-size: 24px;
			@include max($mMobileL) {
				font-size: 18px; } } } }
