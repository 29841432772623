@import "styles/vars";

div.c-two-sided-range {
	div.inputs-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;

		span.connect-word {
			margin: 0 10px;
			color: rgba($colorDark, .54);
			flex-grow: 0;
			flex-shrink: 0;
			font-size: 14px; }

		div.input-wrapper {
			display: flex;
			align-items: center;
			justify-content: stretch;
			height: 32px;
			border: 1px solid rgba($colorDark, .12);
			border-radius: 4px;
			overflow: hidden;
			flex-grow: 1;
			flex-shrink: 1;
			width: 50%;

			input {
				width: 100%;
				flex-grow: 1;
				flex-shrink: 1;
				padding: 0 8px; }

			span.decoration {
				flex-grow: 0;
				flex-shrink: 0;
				height: 100%;
				padding: 0 5px;
				background: rgba($colorDark, .12);
				color: rgba($colorDark, .38);
				display: flex;
				align-items: center;
				justify-content: center; } } } }
