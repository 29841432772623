@import "styles/vars";

div.c-scroll-area {
	height: 240px;
	width: 100%;
	position: relative;
	// background: red

	&:before, &:after {
		content: "";
		@include pos(absolute, 0, 0, 8px, 0);
		pointer-events: none; }

	&:before {
		background: linear-gradient(to top, rgba($colorDark, .06) 0%, rgba($colorDark, 0) 40%, rgba($colorDark, 0) 60%, rgba($colorDark, .06) 100%);
		z-index: 2; }

	&:after {
		background: linear-gradient(to top, rgba($colorDark, .06) 0%, rgba($colorDark, 0) 10%, rgba($colorDark, 0) 90%, rgba($colorDark, .06) 100%);
		z-index: 3; }

	div.scar-inner-wrapper {
		@include pos(absolute, 0, 8px, 0, 0);
		padding: 100px 0;
		overflow-x: hidden;
		overflow-y: scroll;
		background: white;
		@include scrollbar;
		z-index: 1; }

	div.scar-item {
		position: relative;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		cursor: pointer;
		@include transition(color);

		&:not(.selected):hover {
			color: $colorMain; }

		&.selected {
			color: $colorMain;
			font-weight: 700; }

		&:after {
			content: "";
			@include pos(absolute, auto, 15px, 15px, 0);
			height: 1px;
			background: rgba($colorDark, .12); }

		&:last-child {
			&:after {
				content: none; } }

		i.fa-check {
			@include pos(absolute, 0, auto, 0, 0);
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center; } } }
