@import "styles/vars";

div.c-user-badge {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	div.avatar {
		flex-grow: 0;
		flex-shrink: 0;
		width: 64px;
		margin-right: 15px; }

	div.content {
		flex-grow: 1;
		flex-shrink: 1;
		min-width: 0;

		> * {
			line-height: 32px; }

		div.user-name {
			font-weight: 700;
			img {
				height: 20px;
				vertical-align: bottom;
				margin-bottom: 6px; } }


		div.additional-content {
			opacity: .54; } } }
