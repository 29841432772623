@import "styles/vars";

div.c-radio-group {
	div.rg-items {
		div.rg-item {
			margin-bottom: 8px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			cursor: pointer;
			&.selected {
				div.decorator {
					box-shadow: inset 0 0 0 2px rgba($colorMain, 1);
					&:after {
						transform: scale(1) translateZ(0);
						opacity: 1; } } }
			&:not(.selected) {
				&:hover {
					div.decorator {
						box-shadow: inset 0 0 0 2px rgba($colorMain, .54);

						&:before {
							transform: scale(1) translateZ(0);
							opacity: .12; } } } }

			div.decorator {
				@include transition;
				position: relative;
				width: 20px;
				height: 20px;
				margin-top: 2px;
				box-shadow: inset 0 0 0 2px rgba($colorDark, .38);
				border-radius: 50%;
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 10px;
				&:before, &:after {
					content: "";
					@include transition;
					opacity: 0;
					transform: scale(0) translateZ(0);
					background: $colorMain;
					border-radius: 50%; }

				&:before {
					@include pos(absolute, -8px, -8px, -8px, -8px); }

				&:after {
					@include pos(absolute, 4px, 4px, 4px, 4px); } }

			div.content {
				line-height: 24px;
				min-width: 0;
				font-size: 14px; } } } }
