@import "styles/vars";

div.c-number-input {
	div.si-input-wrapper {
		div.u-input {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			padding: 0;
			div.start-decoration {
				flex-grow: 0;
				flex-shrink: 0;
				background: rgba($colorDark, .06);
				border-radius: 8px 0 0 8px;
				margin: 1px 0 1px 1px;
				padding: 0 15px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: rgba($colorDark, .54); }

			input {
				width: 100%;
				flex-grow: 1;
				flex-shrink: 1;
				min-width: 0;
				padding: 0 15px; } } } }
