@import "styles/vars";

main.v-user-order {
	div.uo-content-wrapper {
		max-width: 800px;
		margin: 0 auto;
		padding: 20px;

		div.login-required {
			margin-top: 30px;
			margin-bottom: 30px;
			> h2, p {
				text-align: center; }
			> h2 {
				margin-bottom: 15px;
				font-size: 20px; }
			> p {
				margin-bottom: 30px; }

			div.u-actions {
				justify-content: center; } }

		div.subactions {
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-start; }

		.uo-form {
			margin-top: 30px;

			> * {
				margin-bottom: 30px; }

			.two-input {
				display: flex;
				align-items: flex-start;
				justify-content: center;
				@include max($mMobileL) {
					display: block; }
				> * {
					flex-grow: 1;
					flex-shrink: 1;
					width: 100%;
					margin-right: 30px;
					@include max($mMobileL) {
						margin-right: 0;
						margin-bottom: 30px; }
					&:last-child {
						margin-right: 0; } } }

			> .u-button {
				width: 100%; } } } }
