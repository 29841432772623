@import "styles/vars";

footer.c-app-footer {
	padding: 0 40px;
	@include max($mMobileL) {
		padding: 0 20px; }

	.u-grey-view + & {
		background: $colorBackground; }

	div.caf-inner-wrapper {
		padding: 40px 0;
		max-width: 1120px;
		margin: 0 auto;
		border-top: 1px solid rgba($colorDark, .06);
		@include max($mMobileL) {
			padding-top: 20px; }

		> * {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include max($mMobileL) {
				flex-direction: column; }

			div.link {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 36px;
				pointer-events: none;
				@include max($mMobileL) {
					justify-content: center; }
				&:hover {
					&:after {
						transform: scaleX(1) translateZ(0); } }
				&:after {
					content: "";
					@include pos(absolute, auto, 50%, 0, 0);
					@include transition(transform);
					height: 1px;
					background: rgba($colorMain, .5);
					transform-origin: 100% 0%;
					transform: scaleX(0) translateZ(0);
					@include max($mMobileL) {
						@include pos(absolute, auto, 25%, 25%, 0);
						transform-origin: 50% 0%; } }

				a {
					pointer-events: all;
					@include transition(color);
					color: rgba($colorDark, .7);
					&:hover {
						color: $colorMain; } } } }

		div.routing {
			margin-bottom: 40px;
			@include max($mMobileL) {
				margin-bottom: 20px; }

			.links {
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				@include max($mMobileL) {
					width: 100%;
					margin-top: 20px; }

				.link {
					width: calc(100% / 3);
					@include max($mMobileM) {
						font-size: 12px; } } } }

		div.socials {
			div.links {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				@include max($mMobileL) {
					width: 100%;
					justify-content: space-around;
					margin-bottom: 20px; }

				@include max($mMobileM) {
					font-size: 12px; }
				> * {
					margin-right: 20px;
					@include max($mMobileL) {
						margin-right: 0; } } } }

		div.copyright {
			color: rgba($colorDark, .54);
			@include max($mMobileM) {
				font-size: 12px; } } } }
