@import "styles/vars";

div.c-order-status-description {
	--osd-color: #{$colorDark};
	position: relative;
	padding: 15px;
	border-radius: 6px;
	overflow: hidden;
	&.expired {
		--osd-color: #{$colorNegative}; }
	&.new {
		--osd-color: #{$colorMain}; }
	&.pending {
		--osd-color: #{$colorWarning}; }
	&.rejected {
		--osd-color: #{$colorDanger}; }

	&:before {
		content: "";
		background: var(--osd-color);
		opacity: .06;
		z-index: -1;
		@include pos(absolute, 0, 0, 0, 0); }

	p {
		font-size: 14px;
		margin-bottom: 10px;
		color: var(--osd-color);
		&:last-child {
			margin-bottom: 0; }

		&.description {
			color: $colorDark;
			strong {
				color: var(--osd-color); } } } }
