@import "styles/vars";

div.c-user-icon {
	div.icon-inner-wrapper {
		overflow: hidden;
		&.round {
			border-radius: 50%; }

		.round {
			border-radius: 50%;
			background: rgba($colorDark, .38); }

		div.head {
			width: 50%;
			margin: 0 auto;
			margin-bottom: 10%; } } }

