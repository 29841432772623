@import "styles/vars";

div.c-user-menu-button {
	overflow: hidden;
	border-radius: 50%;
	@include transition;
	&:hover {
		background: rgba($colorDark, .18); }

	.ar-extra-wrapper {
		padding-top: 0 !important; } }

div.c-user-menu {
	padding: 10px 0;

	.um-user-info {
		padding: 0 30px;
		div.name {
			font-weight: 700;
			color: $colorMain;
			margin-bottom: 5px; }

		p.type {
			font-size: 14px;
			text-transform: capitalize; } }


	.um-delimiter {
		margin: 10px 0;
		height: 1px;
		background: rgba($colorDark, .12); }

	.um-item {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		padding: 0 30px;
		cursor: pointer;
		@include transition;
		&:hover {
			background: rgba($colorMain, .12);
			i, span {
				color: $colorMain; } }

		i {
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 15px;
			width: 16px;
			height: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: .38;
			@include transition; }

		span {
			flex-grow: 1;
			flex-shrink: 0;
			font-size: 14px;
			@include transition; } } }
