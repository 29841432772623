@import "styles/vars";

div.c-error-boundary-modal {
	max-width: 840px; }

main.v-error-boundary {
	&.modal {
		min-height: 0;
		padding-top: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
		& + div.u-actions {
			justify-content: center; }
		h1 {
			margin-bottom: 30px;
			@include childSpaceBottom(10px); } }

	div.eb-error-stack {
		text-align: left;
		font-family: monospace;
		font-size: 14px;
		color: $colorNegative;
		max-width: 100%;
		@include childSpaceBottom(10px);
		p {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			span.start {
				flex-grow: 0;
				flex-shrink: 0; }

			span.line {
				border-top: 2px dotted rgba(black, .12);
				flex-grow: 1;
				flex-shrink: 1;
				min-width: 0;
				margin: 0 10px;
				margin-top: 8px; }

			span.end {
				color: black;
				max-width: 50%;
				text-align: right;
				direction: rtl;
				cursor: pointer;
				flex-grow: 0;
				flex-shrink: 1;
				min-width: 0;
				@include toe;
				@include transition(color);
				&:hover {
					color: $colorMain; } } } } }
