@import "styles/vars";

div.c-rating {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 18px;
	div.value {
		font-size: 14px;
		font-weight: 700; }
	div.icon {
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		margin-right: 10px;
		i {
			color: $colorGold;
			display: block;
			position: relative;
			z-index: 3;

			&.fas {
				@include pos(absolute, auto, 0, 0, 0);
				display: flex;
				align-items: flex-end;
				justify-content: center;
				overflow: hidden;
				z-index: 4;
				&.background {
					height: 100%;
					color: white;
					z-index: 2; } } } } }
