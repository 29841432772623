@import "styles/vars";

main.v-error-page {
	strong {
		color: $colorMain; }

	p {
		&.error-code {
			font-size: 24px;
			@include max($mMobileL) {
				font-size: 16px; }
			strong {
				color: $colorNegative; } }
		&:not(.error-code) {
			color: rgba(black, .54);
			text-align: center;
			@include max($mMobileL) {
				font-size: 14px; } } }

	div.actions {
		margin-top: 30px; } }
