@import "styles/vars";

.c-videos-settings {

	.vs-video-picker {
		height: 120px;
		margin-bottom: 60px; }

	.vs-video-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 5px 10px;
		background: white;
		border-radius: 8px;

		> * {
			display: flex;
			align-items: center;
			> * {
				margin-right: 10px;
				&:last-child {
					margin-right: 0; } } }

		div.left {
			justify-content: flex-start;
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			div.meta-data {
				min-width: 0;
				flex-grow: 1;
				flex-shrink: 1;
				p.name, span.size {
					@include toe; }

				p.name {
					font-size: 16px;
					font-weight: 600;
					margin-bottom: 5px; }
				span.size {
					font-size: 14px;
					color: rgba($colorMain, .54); } }

			div.ovi-trigger {
				flex-grow: 0;
				flex-shrink: 0; }

			div.cover {
				width: 120px;
				> * {
					width: 100%;
					padding-top: calc(100% * 9 / 16);
					position: relative;
					border-radius: 8px;
					overflow: hidden;
					background: rgba($colorDark, .12);
					> * {
						@include pos(absolute, 0, 0, 0, 0); } } } }

		div.right {
			justify-content: flex-end;
			flex-grow: 0;
			flex-shrink: 0;
			margin-left: 20px;
			> * {
				@include transition;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: .54;
				cursor: pointer;
				border-radius: 8px;
				&:hover {
					background: rgba($colorMain, .12);
					color: $colorMain; } } } } }
