@import "styles/vars";

div.c-selectable-chip {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	padding: 0 20px 0 15px;
	border-radius: 20px;
	background: white;
	box-shadow: inset 0 0 0 1px rgba($colorDark, .12);
	cursor: pointer;
	@include transition;

	&.selected {
		box-shadow: inset 0 0 0 1px $colorMain;
		> i {
			color: $colorGold;
			opacity: 1; } }

	&:not(.selected) {
		&:hover {
			box-shadow: inset 0 0 0 1px rgba($colorDark, .38); } }

	> i {
		@include transition;
		margin-right: 10px;
		opacity: .38; }

	> span {
		font-size: 14px; } }
