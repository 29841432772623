@import "styles/vars";

.c-order-card {
	display: block;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	position: relative;

	&:not(.expanded) {
		@include transition;
		cursor: pointer;
		&.new {
			&:before {
				content: "";
				@include pos(absolute, 0, 0, auto, 0);
				width: 5px;
				background: $colorMain; } }

		&:hover {
			box-shadow: 0 6px 18px rgba($colorDark, .12);
			header.c-order-card-header {
				div.coc-go {
					i {
						color: $colorMain;
						transform: translateX(0px) translateZ(0); } } } }

		header.c-order-card-header {
			div.coc-right {
				padding-right: 25px; } } } }

.c-order-content {
	padding: 35px 30px;
	position: relative;
	@include childSpaceBottom(30px);
	@include max($mMobileL) {
		padding: 25px 20px;
		@include childSpaceBottom(20px); }

	&:before {
		content: "";
		@include pos(absolute, 0, 30px, 30px);
		height: 1px;
		background: rgba($colorDark, .12);
		@include max($mMobileL) {
			left: 20px;
			right: 20px; } }

	div.badges {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-top: -10px;
		@include childSpaceRight(10px);
		> * {
			margin-top: 10px; } } }
