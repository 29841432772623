@import "styles/vars";

div.c-avatar-round {
	border-radius: 50%;
	overflow: hidden;

	&.no-avatar {
		div.ar-inner-wrapper {
			padding: 5px;
			background: rgba($colorDark, .12); } }

	div.ar-extra-wrapper {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		overflow: hidden;
		padding-top: 5px; } }
