@import "styles/vars";

div.c-smooth-image {
	width: 100%;
	height: 100%;
	background: rgba($colorDark, .18);
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		display: block;
		width: 100%;
		height: 100%;
		animation-duration: 480ms; }

	div.c-image-preloader {
		padding: 0 24%; } }
