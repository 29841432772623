@import "styles/vars";

div.c-checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	user-select: none;
	&:not(.checked):not(.disabled) {
		div.checkbox-inner-wrapper {
			&:hover {
				div.box {
					box-shadow: inset 0 0 0 2px rgba($colorMain, .54); } } } }
	&.checked {
		div.checkbox-inner-wrapper {
			div.box {
				box-shadow: inset 0 0 0 10px $colorMain;
				i {
					opacity: 1;
					transform: scale(1) translateZ(0); } } } }

	div.checkbox-inner-wrapper {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		div.box {
			@include transition;
			width: 20px;
			height: 20px;
			border-radius: 4px;
			box-shadow: inset 0 0 0 1px rgba($colorDark, .24);
			margin-right: 10px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				@include transition;
				font-size: 12px;
				opacity: 0;
				transform: scale(0) translateZ(0); } }

		span.label {
			font-size: 14px;
			line-height: 20px; } } }
