@import "styles/vars";

div.c-image-cropper {
	padding: 40px 30px !important;
	max-width: 600px;
	user-select: none;
	&.shrink {
		max-width: 360px; }

	header {
		font-size: 20px;
		font-weight: 700; }

	div.cropper-content {
		position: relative;
		margin: 30px 0;

		img {
			display: block;
			width: 100%; }

		div.overlay {
			@include pos(absolute, 0, 0, 0, 0);
			z-index: 1;
			background: rgba(black, .7); }

		div.area {
			position: absolute;
			z-index: 2;
			// background: rgba(red, .5)
			cursor: move;

			span {
				$size: 10px;
				$half: $size / 2;
				position: absolute;
				background: white;
				box-shadow: inset 0 0 0 1px rgba(white, .7), inset 0 0 3px 2px rgba(black, .54);
				width: $size;
				height: $size;
				&:nth-child(1) {
					top: -$half;
					left: -$half;
					cursor: nw-resize; }
				&:nth-child(2) {
					top: -$half;
					right: -$half;
					cursor: ne-resize; }
				&:nth-child(3) {
					bottom: -$half;
					right: -$half;
					cursor: se-resize; }
				&:nth-child(4) {
					bottom: -$half;
					left: -$half;
					cursor: sw-resize; } } } } }

