@import "styles/vars";

.c-order-badge {
	--ob-badge-color: #{$colorDark};
	padding: 8px 15px;
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	&.positive {
		--ob-badge-color: #{$colorPositive}; }
	&.warning {
		--ob-badge-color: #{$colorWarning}; }
	&.negative {
		--ob-badge-color: #{$colorNegative}; }
	&.info {
		--ob-badge-color: #{$colorMain}; }

	&:not(.positive):not(.warning):not(.negative):not(.info) {
		&:before {
			opacity: .06; }
		.ob-content {
			font-weight: 400; } }

	&.outline {
		&:before {
			background: transparent !important;
			border-width: 1px;
			border-style: solid;
			border-color: var(--ob-badge-color); } }

	&.compact {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		.ob-label {
			margin-right: 5px; } }

	&:not(.compact) {
		@include max($mTabletB) {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.ob-label {
				margin-right: 5px; } } }

	.ob-label, .ob-content {
		position: relative;
		z-index: 2;
		font-size: 14px;
		line-height: 24px; }

	.ob-label {
		opacity: .38; }

	.ob-content {
		font-weight: 700;
		color: var(--ob-badge-color);
		@include transition; }

	&:before {
		@include transition;
		content: "";
		z-index: 1;
		@include pos(absolute, 0, 0, 0, 0);
		background: var(--ob-badge-color);
		opacity: .12;
		border-radius: 8px; } }
