@import "styles/vars";

div.c-order-user-content {
	.video-review {
		div.data {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			@include max($mTabletB) {
				display: block; }
			> * {
				flex-grow: 1;
				flex-shrink: 1;
				min-width: 0; }

			div.video-wrapper {
				width: 58%;
				@include max($mTabletB) {
					width: 100%; }
				div.video-aspect-ratio-wrapper {
					padding-top: calc(100% / 16 * 9);
					position: relative;
					> * {
						@include pos(absolute, 0, 0, 0, 0); }

					div.c-video {
						background: rgba($colorDark, .12); } } }

			div.form-wrapper {
				width: calc(42% - 30px);
				margin-left: 30px;
				display: flex;
				flex-direction: column;
				align-items: stretch;
				justify-content: space-between;
				@include max($mTabletB) {
					width: 100%;
					margin-left: 0;
					margin-top: 25px; }

				div.rate-n-like {
					display: flex;
					align-items: flex-start;
					justify-content: space-between;
					margin-bottom: 56px;
					flex-grow: 0;
					flex-shrink: 0;
					@include max($mTabletB) {
						margin-bottom: 25px; } }

				div.textarea {
					height: 100%;
					flex-grow: 1;
					flex-shrink: 1;
					min-height: 0;
					height: 100%;
					display: flex;
					align-items: stretch;
					justify-content: flex-start;
					@include max($mTabletB) {
						display: block; }
					div.c-textarea {
						height: 100%;
						flex-grow: 1;
						flex-shrink: 1;
						display: flex;
						flex-direction: column;
						align-items: stretch;
						justify-content: space-between;
						@include max($mTabletB) {
							display: block; }
						label.u-label-input {
							flex-grow: 0;
							flex-shrink: 0; }
						div.textarea-wrapper {
							min-height: 0;
							height: 100%;
							flex-grow: 1;
							flex-shrink: 1;
							textarea.u-input {
								resize: none;
								height: 100%;
								@media (min-width: $mTabletB + 1) {
									min-height: 0; }
								@include max($mTabletB) {
									resize: vertical; } } } } } } }

		div.actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 64px;
			@include max($mTabletB) {
				margin-top: 25px; }
			@include max($mMobileL) {
				display: block; }

			div.buttons {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				@include max($mMobileL) {
					display: block; }
				> * {
					margin-left: 30px;
					@include max($mMobileL) {
						margin-left: 0;
						margin-top: 10px;
						width: 100%; } } } } } }
