@import "styles/vars";

div.c-user-card-search {
	pointer-events: none; }

.c-user-card-search {
	container-type: inline-size;
	container-name: user-card;
	@include transition(transform);

	&.invisible {
		opacity: 0 !important; }

	&:hover {
		transform: scale(1.08) translateZ(0);
		div.info {
			color: $colorMain; } }

	.ucs-avatar {
		margin-bottom: 10px;
		overflow: hidden;
		border-radius: 8px;
		padding-top: calc(100% / 3 * 4);
		&.no-avatar {
			background: rgba($colorDark, .18);
			div.ucs-avatar-inner-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 40px; } } }

	div.ucs-info {
		color: $colorDark;

		.name {
			font-weight: 700;
			font-size: 16px;
			line-height: 32px;
			@include transition;
			&.placeholder {
				height: 1em;
				margin-top: 18px;
				margin-bottom: 13px;
				background: rgba($colorDark, .24);
				width: 80%;
				border-radius: 6px; } }

		.tags {
			font-size: 14px;
			line-height: 24px;
			opacity: .54;
			@include transition;
			&.placeholder {
				height: 1em;
				margin-bottom: 5px;
				background: rgba(black, .08);
				width: 15%;
				border-radius: 6px; } } }

	div.ucs-rating {
		@include pos(absolute, auto, 0, 0, 0);
		padding: 30px 15px 15px 15px;
		background: linear-gradient(to top, rgba(black, .8), rgba(black, 0));
		color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include max($mTabletB) {
			display: block; }

		i.fa-star.background {
			display: none; }

		span.ucs-reviews-count {
			font-size: 14px;
			font-weight: 700;
			opacity: .7;
			@include max($mTabletB) {
				font-size: 12px; } } } }

@container user-card (min-width: 190px) {
	div.ucs-rating {
		display: block; } }
