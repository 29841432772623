@import "styles/vars";

section.c-homepage-actions {
	--ha-size: 210px;
	$size: var(--ha-size);

	position: relative;
	margin-bottom: 95px;
	margin-top: calc(-1 * #{$size} / 2);

	@include max($mTabletA) {
		padding: 0 35px; }
	@include max($mMobileL) {
		padding: 0 20px;
		margin-bottom: 40px; }

	div.ha-content {
		overflow: hidden;
		height: $size;
		border-radius: 16px;
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		box-shadow: 0 4px 24px rgba($colorDark, .08);
		@include max($mMobileL) {
			flex-direction: column-reverse; }
		a {
			width: 50%;
			flex-grow: 0;
			flex-shrink: 0;
			padding: 0 65px;
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: center;
			text-align: left;
			position: relative;
			@include max($mTabletB) {
				justify-content: space-between;
				padding: 35px; }
			@include max($mMobileL) {
				width: 100%;
				height: calc(#{$size} / 2);
				padding: 20px; }
			&:before {
				content: "";
				z-index: 0;
				@include pos(absolute, 0, 0, 0, 0);
				@include transition(opacity);
				transition-duration: 600ms;
				opacity: 0; }

			&:hover {
				&:before {
					opacity: 1; }
				span.caption {
					i {
						transform: translateX(20px) translateZ(0); } } }

			&.creator {
				color: white;
				background: $colorMain;
				&:before {
					background: radial-gradient(farthest-corner at 0 0, rgba($colorDark, 0) 50%, rgba($colorDark, .24)); } }

			&.user {
				background: white;
				@include transition;
				&:before {
					background: radial-gradient(farthest-corner at 100% 0, rgba($colorMain, 0) 50%, rgba($colorMain, .24)); } }

			> span {
				position: relative;
				z-index: 1; }

			span.text {
				line-height: 30px;
				margin-bottom: 10px;
				@include max($mMobileL) {
					line-height: 20px;
					font-size: 12px;
					width: 180px;
					margin-bottom: 0; } }

			span.caption {
				display: flex;
				align-items: center;
				justify-content: space-between;
				span {
					font-size: 24px;
					font-weight: 700;
					line-height: 40px;
					@include max($mMobileL) {
						font-size: 18px;
						line-height: 30px; } }

				i {
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 24px;
					@include transition(transform);
					@include max($mMobileL) {
						font-size: 18px;
						width: 30px; } } } } } }
