@import "styles/vars";

div.c-filepicker {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	div.fp-drop-area {
		@include transition;
		width: 100%;
		height: 100%;
		border: 2px dashed rgba($colorDark, .12);
		border-radius: 8px;
		padding: 20px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: white;

		&:before {
			@include transition;
			@include pos(absolute, 0, 0, 0, 0);
			content: "";
			background: $colorDark;
			opacity: 0; }

		&:not(.invalid):not(.uploading) {
			&:hover, &.highlighted {
				border-color: $colorMain;
				&:before {
					background: $colorMain;
					opacity: .06; } } }

		&.invalid {
			@include singleRunAnimation(shakeX, 600ms);
			border-color: $colorNegative;
			&:before {
				background: $colorNegative;
				opacity: .06; } }

		div.c-progress {
			flex-grow: 0;
			flex-shrink: 0;
			width: 50px !important;
			height: 50px !important; }

		i {
			@include transition;
			color: $colorMain;
			font-size: 24px;
			&.fa-ban {
				color: $colorNegative; } }

		div.fp-label {
			font-size: 16px;
			font-weight: 700;
			color: rgba($colorDark, .54);
			text-align: center;
			margin-top: 20px; } } }
