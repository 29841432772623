@import "styles/vars";

div.c-user-videos-intro {
	padding-bottom: 15px;

	div.uvi-videos-scrollable {
		overflow: hidden;
		overflow-x: auto;
		overflow-y: hidden;
		padding-bottom: 15px;
		@include scrollbar;

		div.uvi-videos {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			height: 240px;
			@include childSpaceRight(15px);
			@include max($mMobileL) {
				height: 180px; }

			> * {
				width: auto !important;
				height: auto !important;
				flex-grow: 0;
				flex-shrink: 0; } } } }
