@import "styles/vars";

main.v-settings {
	div.vs-inner-wrapper {
		padding: 30px 30px;
		@include max($mMobileL) {
			padding: 20px 0; }
		> * {
			margin-bottom: 30px;

			&:not(.c-settings-form) {
				margin-left: auto;
				margin-right: auto;
				max-width: 800px; }

			&:last-child {
				margin-bottom: 0; } } }

	header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		@include max($mMobileL) {
			padding: 0 20px;
			display: block;
			> a {
				margin-top: 20px; } } }

	.c-settings-form {
		> * {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; } }

		div.csf-inner-wrapper {
			margin-left: auto;
			margin-right: auto;
			max-width: 800px;
			@include max($mMobileL) {
				padding: 0 20px; }

			.csf-rows {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				&:not(.plain-early) {
					@include max($mMobileM) {
						display: block;
						@include childSpaceBottom(30px); } }
				&.plain-early {
					@include max($mMobileL) {
						display: block;
						@include childSpaceBottom(30px); } }
				> * {
					width: 100%;
					flex-grow: 1;
					flex-shrink: 1;
					margin-right: 30px;
					&:last-child {
						margin-right: 0; } } } } }

	div.c-settings-form-actions {
		@include transition;
		position: relative;
		z-index: 1200;
		&:before {
			@include pos(absolute, 0, -30px, -30px, 0);
			@include transition;
			content: "";
			z-index: -1;
			background: white;
			opacity: 0;
			@include max($mMobileL) {
				left: 0;
				right: 0; } }

		div.sfa-inner-wrapper {
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include max($mMobileL) {
				display: block;
				padding: 10px 20px;
				> * {
					width: 100%; } } }

		&.active {
			position: sticky;
			bottom: 0;
			&.float {
				&:before {
					box-shadow: 0 5px 20px rgba(black, .12);
					opacity: 1; } } } }

	.form-wrapper {
		> * {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0; } } } }
