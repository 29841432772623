@import "styles/vars";

header.c-order-card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 35px 30px;
	position: relative;
	@include max($mMobileL) {
		padding: 25px 20px;
		display: block; }

	div.coc-left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-grow: 1;
		flex-shrink: 1;

		@include childSpaceRight(30px);

		div.coc-main-content {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-grow: 1;
			flex-shrink: 1;

			div.coc-avatar {
				width: 80px;
				flex-grow: 0;
				flex-shrink: 0; }

			div.coc-text {
				margin-left: 15px;
				flex-grow: 1;
				flex-shrink: 1;
				@include childSpaceBottom(5px);

				h2 {
					font-size: 18px;
					line-height: 22px; }

				div.occasion {
					color: rgba($colorDark, .54); }

				div.price {
					line-height: 25px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					> * {
						flex-grow: 0;
						flex-shrink: 0; }
					div.c-rating {
						margin-left: 15px;
						div.icon {
							margin-right: 5px; }
						div.value {
							font-weight: 400; } }
					span {
						display: inline-block;
						background: $colorPositive;
						color: white;
						padding: 0 8px;
						font-weight: 700;
						border-radius: 6px;
						display: inline-flex;
						align-items: flex-start;
						justify-content: space-between;
						&:before {
							content: "$";
							font-weight: 400;
							margin-right: 5px; } } } } } }

	div.coc-right {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: -10px;
		@include childSpaceRight(10px);
		> * {
			margin-top: 10px; }

		@include max($mTabletB) {
			flex-direction: column-reverse;
			align-items: flex-end;
			@include childSpaceRight(0px); }
		@include max($mMobileL) {
			margin-top: 15px;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: flex-end;
			flex-wrap: wrap-reverse;
			> * {
				margin-right: 10px !important;
				&:first-child {
					margin-right: 0 !important; } } }

		div.coc-status {
			line-height: 40px;
			padding: 0 15px;
			border-radius: 6px;
			font-size: 14px;
			font-weight: 700;
			background: black;
			color: white;
			width: 130px;
			text-align: center;
			@include max($mMobileL) {
				width: auto;
				flex-grow: 0;
				flex-shrink: 0; }
			&.new {
				color: $colorMain;
				background: rgba($colorMain, .12); }
			&.pending {
				color: $colorWarning;
				background: rgba($colorWarning, .12); }
			&.completed {
				color: $colorPositive;
				background: rgba($colorPositive, .12); }
			&.expired {
				color: $colorNegative;
				background: rgba($colorNegative, .12); }
			&.rejected {
				color: $colorDanger;
				background: rgba($colorDanger, .12); }
			i {
				margin-right: 10px; } } }

	div.coc-go {
		@include pos(absolute, 0, auto, 0, 0);
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include max($mMobileL) {
			width: 30px;
			height: 40px;
			top: auto;
			bottom: 25px; }
		i {
			@include transition;
			color: rgba($colorDark, .54);
			transform: translateX(-5px) translateZ(0); } } }
