@import "styles/vars";

div.c-confirm-modal {
	div.sm-outer-wrapper {
		div.sm-inner-wrapper {
			padding: 30px;
			max-width: 480px;
			@include max($mMobileL) {
				padding: 30px 20px; }
			> * {
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0; } }

			h1 {
				margin-bottom: 30px;
				@include max($mMobileL) {
					font-size: 24px; } }

			p:not(:last-of-type) {
				margin-bottom: 15px; }

			p.u-invalidation-message {
				font-size: 16px;
				padding-left: 0;
				&:not(.visible) {
					margin-bottom: 15px; } }

			div.cm-actions {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				> * {
					margin-left: 10px;
					&:first-child {
						margin-left: 0; } } } } } }
