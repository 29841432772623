@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	src: url("/static/fonts/open-sans-300.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	font-style: italic;
	src: url("/static/fonts/open-sans-300-i.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	src: url("/static/fonts/open-sans-400.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	font-style: italic;
	src: url("/static/fonts/open-sans-400-i.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 600;
	src: url("/static/fonts/open-sans-600.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 600;
	font-style: italic;
	src: url("/static/fonts/open-sans-600-i.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	src: url("/static/fonts/open-sans-700.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	font-style: italic;
	src: url("/static/fonts/open-sans-700-i.woff") format("woff");
	font-display: swap; }



/* Condensed */

@font-face {
	font-family: "Open Sans Condensed";
	font-weight: 300;
	src: url("/static/fonts/open-sans-cond-300.woff") format("woff");
	font-display: swap; }

@font-face {
	font-family: "Open Sans Condensed";
	font-weight: 700;
	src: url("/static/fonts/open-sans-cond-700.woff") format("woff");
	font-display: swap; }
