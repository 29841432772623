@import "styles/vars";

div.c-homepage-users-list {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-right: -40px;
	margin-bottom: -40px;
	@include max($mMobileL) {
		margin-right: -30px;
		margin-bottom: -30px; }

	> * {
		width: calc(100% / 5 - 40px);
		margin-right: 40px;
		margin-bottom: 40px;
		@include max($mTabletB) {
			width: calc(100% / 3 - 40px); }
		@include max($mMobileL) {
			width: calc(50% - 30px);
			margin-right: 30px;
			margin-bottom: 30px; } } }
