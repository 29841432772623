@import "styles/vars";

main.v-playground {
	padding: 120px;
	@include max($mMobileL) {
		padding: 120px 30px; }
	div.c-linear-tabs {
		margin-bottom: 20px; }
	div.test-action {
		padding: 20px;
		> * {
			margin: 0 auto; } }

	form {
		margin: 0 auto;
		width: 360px;
		> * {
			margin-bottom: 30px; } }

	.square {
		width: 320px;
		height: 320px;
		margin: 0 auto; } }
