@import "styles/vars";

div.c-chip {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	border-radius: 8px;
	box-shadow: 0 0 0 1px rgba($colorDark, .06);
	padding-left: 16px;
	background: white;
	font-size: 14px;
	@include transition;
	&:hover {
		box-shadow: 0 0 0 1px rgba($colorDark, .12); }

	> .content {
		&:last-child {
			margin-right: 16px; } }

	> i {
		@include transition;
		color: rgba($colorDark, .38);
		cursor: pointer;
		font-size: 16px;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover {
			transform: rotate(90deg) translateZ(0); } } }
