@import "styles/vars";

section.c-homepage-call-to-action {
	height: 200px;
	overflow: hidden;
	border-radius: 16px;
	background: $colorMain;
	margin-bottom: 40px;
	position: relative;
	@include max($mTabletA) {
		border-radius: 0; }

	div.hcta-content {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 95px;
		@include max($mTabletB) {
			padding: 0 30px; }
		@include max($mMobileL) {
			padding: 0 20px;
			flex-direction: column;
			justify-content: center; }

		h2 {
			color: white;
			font-size: 32px;
			@include max($mMobileL) {
				font-size: 24px;
				text-align: center;
				margin-bottom: 20px; } } }

	@keyframes rotate {
		0% {
			transform: rotate(0) translateZ(0); }
		100% {
			transform: rotate(360deg) translateZ(0); } }

	div.hcta-fancy-background {
		@include pos(absolute, 0, 0, 0, 0);
		--hcta-size: 600px;
		pointer-events: none;
		$size: var(--hcta-size);
		@include max($mTabletA) {
			--hcta-size: 500px; }
		@include max($mTabletB) {
			--hcta-size: 400px; }

		div {
			background: rgba(white, .12);
			width: $size;
			height: $size;
			position: absolute;
			border-radius: 240px 280px 220px 300px;
			animation-name: rotate;
			animation-iteration-count: infinite;
			animation-timing-function: linear;

			&:nth-child(1) {
				top: calc(-1 * #{$size} + 140px);
				left: calc(-1 * #{$size} / 2);
				border-radius: 240px 280px 220px 300px;
				animation-duration: 7200ms;
				animation-direction: reverse; }

			&:nth-child(2) {
				bottom: calc(-1 * #{$size} + 100px);
				left: 100px;
				border-radius: 240px 220px 300px 280px;
				animation-duration: 5200ms;
				@include max($mMobileL) {
					display: none; } }

			&:nth-child(3) {
				top: calc(-1 * #{$size} + 100px);
				right: 80px;
				border-radius: 300px 280px 240px 220px;
				animation-duration: 5400ms;
				animation-direction: reverse;
				@include max($mMobileL) {
					display: none; } }

			&:nth-child(4) {
				right: calc(-1 * #{$size} / 2);
				bottom: calc(-1 * #{$size} + 120px);
				border-radius: 300px 280px 220px 240px;
				animation-duration: 6000ms; } } } }
