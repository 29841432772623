@import "styles/vars";

div.c-switch-tabs {
	height: 40px;
	padding: 4px;
	border-radius: 8px;
	background: rgba($colorDark, .06);

	div.items {
		display: flex;
		align-items: stretch;
		justify-content: center;
		position: relative;
		height: 100%;
		width: 100%;

		div.item {
			@include transition;
			width: 100%;
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;

			&:not(.selected) {
				color: rgba($colorDark, .38);
				cursor: pointer;
				&:hover {
					color: rgba($colorDark, .54); } }

			&.selected {
				color: $colorDark; } }

		div.thumb {
			@include pos(absolute, 0);
			@include transition;
			z-index: 1;
			height: 100%;
			border-radius: 6px;
			background: white;
			box-shadow: 0 0 10px rgba($colorDark, .06);
			pointer-events: none; } } }
