@import "styles/vars";

div.c-network-status {
	@include pos(fixed, auto, 0, 0, 0);
	z-index: 99999;
	text-align: center;
	padding: 5px 15px;
	font-size: 12px;
	font-weight: 700;
	color: white;
	@include transition(color, background);
	&.offline {
		background: $colorNegative;
		// color: white
		@include singleRunAnimation(shiftInYFull); }
	&.online {
		background: $colorPositive;
		// color: $colorDark
		@include singleRunAnimation(fadeOut, 2400ms);
		animation-delay: 1200ms; } }
