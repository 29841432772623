@import "styles/vars";

form.su-step.step-4 {
	.email-field {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include max($mMobileL) {
			display: block; }

		> * {
			flex-grow: 1;
			flex-shrink: 1; }

		> button {
			flex-grow: 0;
			flex-shrink: 0;
			margin-left: 10px;
			margin-top: 30px;
			width: 141px;
			@include max($mMobileL) {
				width: 100%;
				margin-top: 15px;
				margin-left: 0; } } }

	div.su-server-error {
		text-align: center;
		color: $colorNegative;
		font-size: 14px; } }

