@import "styles/vars";

div.c-ordering-vertical {
	overflow: visible;

	div.ov-item {
		position: relative;
		overflow: visible;
		&.dragged {
			pointer-events: none;
			height: 5px;
			background: rgba($colorDark, .12);
			border-radius: 8px;
			z-index: 1199;
			div.ovi-inner-wrapper {
				opacity: .96;
				transition: none;
				z-index: 1199;
				> * {
					box-shadow: 0 6px 18px rgba(black, .24); } } }

		div.ovi-inner-wrapper {
			@include transition; }

		div.ovi-trigger {
			padding: 5px;
			cursor: move;
			touch-action: none;
			i {
				opacity: .38;
				pointer-events: none; } } }

	div.ovi-placemaker {
		@include transition;
		overflow: visible;
		background: rgba(blue, .24);
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 8;
		opacity: 0;
		border-radius: 8px;
		border-width: 2px;
		border-style: dashed;
		border-color: transparent;
		pointer-events: all !important;
		margin: {
			top: 5px;
			bottom: 5px; }
		&:not(.highlighted) {
			height: 0 !important; }
		&:before {
			content: "";
			display: block;
			height: calc(100% + 40px);
			width: 100%;
			@include transition; }
		&.highlighted {
			height: 50px;
			background: rgba($colorMain, .12);
			border-color: $colorMain;
			opacity: 1; }
		&.intact {
			pointer-events: none !important; } } }
