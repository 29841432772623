@import "styles/vars";

section.c-homepage-how-it-works {
	padding: 95px 120px;
	border-radius: 16px;
	@include max($mTabletA) {
		border-radius: 0; }
	@include max($mTabletB) {
		padding: 55px 40px; }
	@include max($mMobileL) {
		padding: 55px 20px; }

	h1, h2 {
		text-align: center;
		font-size: 48px;
		line-height: 55px;
		margin-bottom: 35px;
		@include max($mMobileL) {
			font-size: 36px;
			line-height: 40px; } }

	div.hhiw-cta {
		margin-top: 35px; }

	div.hhiw-tabs {
		margin-bottom: 35px;

		div.hhiw-tabs-outer-wrapper {
			width: 360px;
			margin: 0 auto;
			padding: 8px;
			border-radius: 48px;
			background: rgba($colorDark, .06);
			@include max($mMobileL) {
				width: 100%; }
			div.hhiw-tabs-inner-wrapper {
				display: flex;
				align-items: stretch;
				justify-content: center;
				position: relative;

				> * {
					height: 40px;
					border-radius: 20px; }

				span.highlighter {
					@include pos(absolute, 0);
					background: white;
					@include transition(left); }

				> *:not(.highlighter) {
					width: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					cursor: pointer;
					@include transition(background);
					font-weight: 700;
					@include max($mMobileL) {
						font-size: 14px; }
					&:hover {
						background: rgba(white, .38); } } } } } }

article.c-homepage-how-it-works-accordion {
	padding: 40px 0;
	border-bottom: 1px solid rgba(black, .12);
	&:last-child {
		border-bottom: none; }

	header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		@include transition(margin-bottom);
		&.expanded {
			margin-bottom: 15px;
			i {
				transform: rotateX(-180deg) translateZ(0); } }

		&:hover {
			i {
				background: mix($colorMain, $colorDark, 76%); } }

		i {
			flex-grow: 0;
			flex-shrink: 0;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			background: $colorMain;
			@include transition(background, transform);
			margin-right: 40px;
			&:before {
				margin-top: 2px; } }

		h2, h3 {
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			font-size: 24px;
			line-height: 40px;
			font-weight: 700;
			@include max($mMobileL) {
				font-size: 16px;
				line-height: 30px; } } }

	p {
		padding-left: 75px;
		line-height: 32px; } }
