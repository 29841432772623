@import "styles/vars";

@keyframes catloader {
	0% {
		width: 100%; }
	100% {
		width: 0%; } }

main.v-homepage {
	padding-bottom: 65px;
	overflow-x: hidden;
	h2 {
		font-weight: 700; }

	.ho-wrapper {
		width: 100%;
		max-width: 1120px;
		margin-left: auto;
		margin-right: auto;
		&.fadeout-list {
			.c-homepage-users-list {
				@include singleRunAnimation(fadeOut, 1000ms, cubic-bezier(.89,-0.02,.83,.67));
				animation-delay: 9000ms; } } }

	.ho-featured-users {
		margin-bottom: 40px;
		@include max($mTabletA) {
			padding: 0 35px; }
		@include max($mMobileL) {
			padding: 0 20px; }

		&.other {
			margin-bottom: 95px;
			@include max($mMobileL) {
				margin-bottom: 40px; } }

		h2, .c-additional-filters {
			margin-bottom: 40px;
			@include max($mMobileL) {
				margin-bottom: 30px; } }

		.hofu-loader {
			height: 5px;
			background: $colorMain;
			margin-top: -23px;
			margin-bottom: 18px;
			@include singleRunAnimation(catloader, 10000ms, linear);
			@include max($mMobileL) {
				margin-top: -18px;
				margin-bottom: 13px; } } }

	.ho-action {
		border-radius: 16px;
		font-size: 14px;
		font-weight: 700;
		line-height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 15px 55px;
		@include transition(transform);

		&.white {
			background: white;
			color: $colorMain; }
		&.blue {
			background: $colorMain;
			color: white; }

		&:hover {
			transform: scale(1.1) translateZ(0); } } }
