@import "styles/vars";

div.c-user-review {

	div.ur-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0; }

		div.user {
			font-size: 16px;
			font-weight: 700; }

		div.rating {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			div.stars {
				> * {
					color: $colorGold;
					margin-right: 8px; } } } }

	div.ur-content {
		p {
			font-size: 16px;
			line-height: 30px; } } }
