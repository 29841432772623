@import "styles/vars";

div.c-order-occasion {
	&.invalid {
		div.items {
			box-shadow: inset 0 0 0 1px $colorNegative; } }

	div.items {
		box-shadow: inset 0 0 0 1px rgba($colorDark, .12);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 16px;
		flex-wrap: wrap;
		border-radius: 8px;
		@include transition;

		> * {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 8px; } } }
