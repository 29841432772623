@import "styles/vars";

main.v-account-orders-list {
	div.vaol-inner-wrapper {
		padding: 25px 40px;
		max-width: 1120px;
		margin: 0 auto;
		@include max($mMobileL) {
			padding: 25px 0; }

		div.load-more {
			margin: 35px 0 10px 0; } } }
