@import "styles/vars";

div.c-progress {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	span.counter {
		font-weight: 700;
		font-size: 12px;
		color: $colorMain; }

	svg {
		@include pos(absolute, 0, 0, 0, 0);
		width: 100%;
		height: 100%;

		circle {
			stroke-width: 4px;
			fill: none; }

		circle.background {
			stroke: rgba($colorMain, .24); }

		circle.progress {
			stroke: $colorMain;
			transform-origin: 50% 50%;
			transform: rotate(-90deg) translateZ(0); } } }
