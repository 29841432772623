@import "styles/vars";

div.c-video {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 8px;

	div.cv-duration {
		@include pos(absolute, auto, auto, 0, 0);
		background: rgba(black, .72);
		padding: 3px 8px;
		color: white;
		font-size: 12px;
		font-weight: 700; }

	div.cv-preloader {
		@include pos(absolute, 0, 0, 0, 0);
		display: flex;
		align-items: center;
		justify-content: center; }

	div.play-button {
		$size: 80px;
		width: $size;
		height: $size;
		color: $colorMain;
		background: white;
		@include pos(absolute, calc(50% - #{$size / 2}), calc(50% - #{$size / 2}));
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		@include transition;
		cursor: pointer;
		border-radius: 50%;
		&:hover {
			background: $colorMain;
			color: white; } }

	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
		display: block; } }
