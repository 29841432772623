@import "styles/vars";

div.c-user-card {
	@include max($mTabletB) {
		display: flex;
		align-items: center;
		justify-content: flex-start; }
	@include max($mMobileL) {
		display: block !important;
		padding: 20px !important; }

	div.uc-avatar-wrapper {
		width: 100%;
		flex-grow: 0;
		flex-shrink: 0;
		@include max($mTabletB) {
			width: 200px; }
		@include max($mMobileL) {
			width: 120px !important;
			margin: 0 auto !important;
			border-radius: 8px;
			overflow: hidden;
			margin-bottom: 20px !important; } }

	.uc-user-icon {
		padding: 50px;
		background: rgba($colorDark, .18);
		@include max($mMobileL) {
			padding: 30px; } }

	div.uc-content {
		padding: 30px;
		min-width: 0;
		@include max($mTabletB) {
			padding: 0 30px; }
		@include max($mMobileL) {
			padding: 0; } }

	p.uc-subtext {
		line-height: 24px;
		font-size: 14px;
		color: rgba($colorDark, .54);
		@include toe;
		@include max($mMobileL) {
			text-align: center; } }

	div.uc-stats {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@include max($mMobileL) {
			justify-content: center; }
		> * {
			margin-right: 20px;
			&:last-child {
				margin-right: 0; } } }

	div.uc-categories {
		@include max($mMobileL) {
			text-align: center;
			font-size: 14px; } }

	h1 {
		font-size: 24px;
		line-height: 30px;
		@include max($mMobileL) {
			text-align: center;
			font-size: 18px; }
		img {
			vertical-align: bottom;
			height: 24px;
			margin-bottom: 3px;
			@include max($mMobileL) {
				height: 20px;
				margin-bottom: 5px; } } } }
