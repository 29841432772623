@import "styles/vars";

div.c-order-creator-content {
	p.otc-text {
		font-size: 16px;
		line-height: 32px;

		&.light {
			color: rgba($colorDark, .54); } }

	.video-uploader {
		div.filepicker {
			margin-bottom: 30px;
			height: 345px;
			&.has-file {
				background: #3B3D40;
				border-radius: 8px;
				color: white;
				display: flex;
				align-items: stretch;
				justify-content: space-between;
				overflow: hidden;
				@include max($mTabletA) {
					height: auto;
					display: block; }

				video {
					@include max($mTabletA) {
						width: 100%; } }

				div.content {
					flex-grow: 1;
					flex-shrink: 1;
					min-width: 0;
					padding: 30px;
					@include childSpaceBottom(15px);

					div.name {
						@include childSpaceRight(15px);
						word-wrap: break-word;
						i:not(.fa-pulse) {
							@include transition;
							cursor: pointer;
							&:hover {
								opacity: .7; } } } } } }

		div.actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include max($mMobileL) {
				display: block;
				> * {
					width: 100%; } } } } }
