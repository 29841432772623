@import "styles/vars";

main.v-private-view {
	p {
		text-align: center; }

	.u-actions {
		@include max($mMobileL) {
			width: 100%;
			display: block;
			margin-top: 20px;
			@include childSpaceRight(0);
			@include childSpaceBottom(10px); } } }
