@import "styles/vars";

div.c-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	@include transition(opacity, filter);
	&.disabled {
		pointer-events: none;
		opacity: .54;
		filter: grayscale(1); }

	> * {
		width: 32px;
		height: 32px;
		margin-right: 5px;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:last-child {
			margin-right: 0; } }

	i {
		@include transition;
		opacity: .54;
		cursor: pointer;
		&:not(.hidden) {
			&:hover {
				background: rgba($colorMain, .12);
				opacity: 1;
				color: $colorMain; } }
		&.hidden {
			opacity: 0;
			pointer-events: none; } }

	span.page {
		@include transition;
		&.current {
			background: $colorMain;
			color: white;
			font-weight: 700; }
		&.default {
			cursor: pointer;
			&:hover {
				color: $colorMain;
				background: rgba($colorMain, .12); } }
		&.delimiter {
			opacity: .54;
			pointer-events: none; } } }
