@import "styles/vars";

@keyframes fadeIn {
	0% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes fadeInWithScaleY {
	0% {
		transform: scaleY(.96) translateZ(0);
		opacity: 0; }
	100% {
		transform: scaleY(1) translateZ(0);
		opacity: 1; } }

@keyframes fadeInWithScaleYBigger {
	0% {
		transform: scaleY(.8) translateZ(0);
		opacity: 0; }
	100% {
		transform: scaleY(1) translateZ(0);
		opacity: 1; } }

@keyframes fadeInWithScaleOut {
	0% {
		transform: scale(1.2) translateZ(0);
		opacity: 0; }
	100% {
		transform: scale(1) translateZ(0);
		opacity: 1; } }

@keyframes scaleIn {
	0% {
		transform: scale(0) translateZ(0); }
	100% {
		transform: scale(1) translateZ(0); } }

@keyframes fadeOut {
	0% {
		opacity: 1; }
	100% {
		opacity: 0; } }

@keyframes ring {
	0%, 100% {
		transform: rotate(0deg) translateZ(0); }
	20%, 60% {
		transform: rotate(8deg) translateZ(0); }
	40%, 80% {
		transform: rotate(-8deg) translateZ(0); } }

@keyframes shiftInY {
	0% {
		transform: translateY(50px) translateZ(0);
		opacity: 0; }
	100% {
		transform: translateZ(0);
		opacity: 1; } }

@keyframes shiftInYFull {
	0% {
		transform: translateY(100%) translateZ(0);
		opacity: 0; }
	100% {
		transform: translateZ(0);
		opacity: 1; } }

@keyframes shiftInYReverse {
	0% {
		transform: translateY(-50px) translateZ(0);
		opacity: 0; }
	100% {
		transform: translateZ(0);
		opacity: 1; } }

@keyframes shiftInX {
	0% {
		transform: translateX(50px) translateZ(0);
		opacity: 0; }
	100% {
		transform: translateZ(0);
		opacity: 1; } }

@keyframes shiftInXReverse {
	0% {
		transform: translateX(-50px) translateZ(0);
		opacity: 0; }
	100% {
		transform: translateZ(0);
		opacity: 1; } }

@keyframes shiftOutX {
	0% {
		transform: translateZ(0);
		opacity: 1; }
	100% {
		transform: translateX(-50px) translateZ(0);
		opacity: 0; } }

@keyframes shiftOutXReverse {
	0% {
		transform: translateZ(0);
		opacity: 1; }
	100% {
		transform: translateX(50px) translateZ(0);
		opacity: 0; } }

@keyframes shakeX {
	10%, 90% {
		transform: translateX(-1px) translateZ(0); }
	20%, 80% {
		transform: translateX(2px) translateZ(0); }
	30%, 50%, 70% {
		transform: translateX(-4px) translateZ(0); }
	40%, 60% {
		transform: translateX(4px) translateZ(0); } }
