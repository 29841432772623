@import "styles/vars";

@keyframes succeedIn {
	0% {
		box-shadow: inset 0 0 0 $colorPositive; }
	100% {
		box-shadow: inset 0 0 0 25px $colorPositive; } }

@keyframes failedIn {
	0% {
		box-shadow: inset 0 0 0 $colorNegative; }
	100% {
		box-shadow: inset 0 0 0 25px $colorNegative; } }

div.c-video-upload-progress {
	@include pos(fixed, auto, auto, 30px, 30px);
	width: 300px;
	padding: 10px;
	background: white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 8px;
	box-shadow: 0 1px 1px rgba(black, .12), 0 6px 18px rgba(black, .12);
	@include singleRunAnimation(shiftInYFull);

	&.finished {
		animation-name: fadeOut;
		animation-duration: 1200ms;
		animation-delay: 2400ms;
		&.failed {
			animation-name: shakeX, fadeOut;
			animation-duration: 720ms, 1200ms;
			animation-delay: 0ms, 2400ms; } }

	&:hover {
		.vup-progress {
			.c-progress {
				.vup-count-wrapper {
					background: rgba($colorNegative, .12);
					span.vup-count {
						display: none; }
					i {
						display: block; } } } } }

	.vup-progress {
		position: relative;

		.vup-finish {
			@include pos(absolute, 0, 0, 0, 0);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			&.fail {
				@include singleRunAnimation(failedIn, 400ms); }
			&.success {
				@include singleRunAnimation(succeedIn, 400ms); }
			i {
				@include singleRunAnimation(scaleIn, 480ms, cubic-bezier(.09,.76,.2,1.79));
				color: white; } }

		.c-progress {
			width: 50px !important;
			height: 50px !important;
			position: relative;
			svg {
				pointer-events: none; }

			.vup-count-wrapper {
				@include pos(absolute, 5px, 5px, 5px, 5px);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				cursor: pointer;
				@include transition(background);
				i {
					display: none;
					font-size: 16px;
					color: $colorNegative; } } } }

	.vup-info {
		flex-grow: 1;
		flex-shrink: 1;
		min-width: 0;
		margin-left: 10px;
		color: rgba($colorDark, .54);
		overflow: hidden;
		text-overflow: ellipsis;
		strong {
			color: $colorMain; } } }
