@import "styles/vars";

div.c-chips-autocomplete {
	.show-first {
		margin-top: 10px;
		div.items {
			> * {
				margin-right: 10px;
				font-size: 14px; } } }
	.u-input {
		position: relative;
		height: auto;
		min-height: 56px;
		padding: 12px 16px 4px 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		> *:not(.autocomplete) {
			margin-right: 8px;
			margin-bottom: 8px; }

		div.input-wrapper {
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 100px;
			position: relative;
			input {
				width: 100%;
				height: 32px;
				@include placeholder {
					color: rgba($colorDark, .38); } } }

		div.autocomplete {
			@include pos(absolute, calc(100% + 5px), 0, 0);
			padding: 5px 0;
			border-radius: 5px;
			max-width: 100%;
			background: white;
			box-shadow: 0 12px 24px rgba($colorDark, .18);
			z-index: 1200;
			@include singleRunAnimation(fadeInWithScaleYBigger);
			transform-origin: 0% 0%;
			> * {
				padding: 0 10px;
				line-height: 40px;
				font-size: 14px;
				@include transition;
				position: relative;
				&:not(.empty) {
					cursor: pointer; }
				&:before {
					content: "";
					@include pos(absolute, 0, 0, auto, 0);
					width: 5px;
					background: $colorMain;
					transform-origin: 0% 0%;
					transform: scaleX(0) translateZ(0);
					pointer-events: none;
					opacity: .24;
					@include transition; }

				&.highlighted {
					background: rgba($colorMain, .12);
					color: $colorMain;
					padding-left: 15px;
					&:before {
						transform: scaleX(1) translateZ(0); } } } } } }
