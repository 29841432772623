@import "styles/vars";

div.c-searchbox {
	position: relative;
	i {
		@include transition;
		@include pos(absolute, 0, 0);
		opacity: .38;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		&:hover {
			opacity: .54; } }

	input {
		border-radius: 8px;
		height: 40px;
		padding-left: 45px;
		font-size: 14px; } }
