@import "styles/vars";

div.c-additional-filters {
	padding: 0;
	span.u-dimmed {
		margin-left: 5px; }

	p.af-not-found {
		line-height: 40px;
		font-weight: 20px;
		margin-bottom: 15px;
		text-align: center;
		width: 100%; }

	div.af-details {
		div.afd-content {
			padding: 15px 25px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			@include childSpaceRight(15px);
			@include max($mMobileS) {
				padding: 15px; }

			div.c-additional-filters-chip {
				animation-name: none;
				margin-bottom: 15px; } } }

	section.af-main {
		min-height: 70px;
		padding: 15px 25px;
		overflow: hidden;
		@include max($mMobileS) {
			padding: 15px; }
		&:not(.u-center) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include max($mMobileL) {
				display: block; } }

		div.afm-list {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: relative;
			flex-grow: 1;
			flex-shrink: 1;
			min-width: 0;
			overflow: hidden;
			@include childSpaceRight(15px);
			&:before {
				content: "";
				@include pos(absolute, 0, auto, 0, 0);
				width: 60px;
				background: linear-gradient(to left, white 20%, rgba(white, 0));
				z-index: 12; }

			&.expanded {
				div.c-additional-filters-chip {
					animation-name: none;
					opacity: 0; } }

			div.afm-search {
				@include pos(absolute, 0, 0, 30px, 0);
				z-index: 13;
				animation-name: shiftInYReverse;
				@include max($mMobileL) {
					right: 0; }
				div.u-searchbox {
					width: 100%; } } }

		div.afm-actions {
			border-left: 1px solid rgba($colorDark, .12);
			padding-left: 15px;
			flex-grow: 0;
			flex-shrink: 0;
			@include max($mMobileL) {
				padding-left: 0;
				padding-top: 15px;
				margin-top: 15px;
				border-left: none;
				border-top: 1px solid rgba($colorDark, .12); }
			.afm-action {
				padding: 5px 15px;
				cursor: pointer;
				border-radius: 4px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				@include transition;
				@include max($mMobileS) {
					font-size: 14px; }
				&:hover {
					background: rgba($colorMain, .12);
					> *:not(.fas):not(.u-dimmed) {
						color: $colorMain; }
					.fas {
						opacity: 1; } }
				> * {
					@include transition; }

				span.u-dimmed {
					margin-left: 10px; }

				i.fas {
					margin-left: 10px;
					@include max($mMobileL) {
						margin-left: auto; } } } } } }


div.c-additional-filters-chip {
	height: 40px;
	padding: 5px 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $colorBackground;
	box-shadow: inset 0 0 0 1px rgba($colorDark, .06);
	border-radius: 4px;
	cursor: pointer;
	animation-name: shiftInY;
	@include transition;
	@include max($mMobileS) {
		font-size: 14px; }
	&:not(.selected):hover {
		background: rgba($colorMain, .06);
		box-shadow: inset 0 0 0 1px rgba($colorMain, .38); }

	&.selected {
		background: rgba($colorMain, .06);
		box-shadow: inset 0 0 0 2px rgba($colorMain, .54);
		> span:not(.u-dimmed) {
			color: $colorMain;
			font-weight: 600; } }

	> span:not(.u-dimmed) {
		@include transition; }

	@for $i from 1 through 10 {
		&:nth-child(#{$i}) {
			animation-delay: #{($i - 1) * 60}ms; } } }
