@import "styles/vars";

div.c-datepicker {
	div.dp-input-wrapper {
		position: relative;
		i {
			@include pos(absolute, 0, auto, 0);
			width: 55px;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 20px;
			color: rgba($colorDark, .38);
			pointer-events: none;
			@include max($mMobileL) {
				pointer-events: all; } } } }

div.c-datepicker-select {
	position: absolute;
	z-index: 10001;
	box-shadow: 0 12px 24px rgba($colorDark, .18);
	transform-origin: 50% 0%;
	@include singleRunAnimation(fadeInWithScaleY);
	@include max($mMobileL) {
		position: fixed;
		top: 0 !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		background: rgba(black, .72);
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center; }

	&:before {
		content: "";
		background: white;
		width: 12px;
		height: 12px;
		@include pos(absolute, -6px, auto, 21px);
		transform: rotate(-45deg);
		@include max($mMobileL) {
			content: none; } }

	div.cds-inner-wrapper {
		padding: 30px;
		position: relative;
		background: white;
		@include max($mMobileL) {
			width: 100%;
			max-width: 360px;
			padding: 30px 15px; } }

	.default-hover {
		cursor: pointer;
		@include transition;
		&:hover {
			color: $colorMain;
			background: rgba($colorMain, .12); } }

	div.month-year-overlay {
		@include pos(absolute, 0, 0, 0, 0);
		z-index: 2;
		background: white;
		@include singleRunAnimation(fadeIn);
		padding: 30px;
		overflow: hidden;
		div.switch {
			height: 32px;
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			margin-bottom: 16px;
			&.month .month, &.year .year {
				box-shadow: inset 0 0 0 16px $colorMain !important;
				color: white; }

			i {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				border-radius: 8px;
				color: rgba($colorDark, .38); }

			.item {
				color: $colorMain;
				box-shadow: inset 0 0 0 1px $colorMain;
				@include transition;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				margin-right: 5px;
				padding: 0 16px;
				cursor: pointer;
				&:hover {
					background: rgba($colorMain, .12); }
				&.month {
					flex-grow: 1;
					flex-shrink: 1;
					min-width: 0; } } }

		div.overlay-content {
			height: calc(100% - 48px);
			@include singleRunAnimation(shiftInX);
			&.fade-out {
				@include singleRunAnimation(shiftOutX); }
			&.backward {
				@include singleRunAnimation(shiftInXReverse);
				&.fade-out {
					@include singleRunAnimation(shiftOutXReverse); } }

			.inner-wrapper {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center; } } }


	div.month-year-switch {
		display: flex;
		align-items: stretch;
		justify-content: center;
		margin-bottom: 24px;

		div {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 0;
			flex-grow: 1;
			flex-shrink: 1;
			text-align: center;
			border-radius: 8px; } }


	div.days {
		div.row {
			width: 320px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			@include max($mMobileL) {
				width: 100%; }
			&:last-child {
				margin-bottom: 0; }
			> * {
				width: 32px;
				height: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				border-radius: 50%;
				@include transition;
				position: relative;
				background: white;
				&.today {
					&:before {
						content: "";
						z-index: -1;
						@include pos(absolute, -1px, -1px, -1px, -1px);
						border-radius: 50%;
						background: radial-gradient(circle at 0% 0%, $colorNegative, transparent 80%); }
					&:after {
						content: "";
						z-index: 1;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: white;
						box-shadow: inset 0 0 0 1px $colorNegative;
						@include pos(absolute, 2px, 2px); } } }

			&.daynames {
				> * {
					font-weight: 700;
					color: rgba($colorDark, .38); } }

			&:not(.daynames) {
				> *:not(.empty) {
					cursor: pointer;
					&.disabled {
						opacity: .38;
						background: rgba(black, .12);
						cursor: not-allowed; }
					&:not(.selected):not(.disabled):hover {
						box-shadow: 0 0 0 1px rgba($colorDark, .12); }
					&.selected {
						box-shadow: inset 0 0 0 16px $colorMain;
						color: white; } } } } } }


