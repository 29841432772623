@import "styles/vars";

div.c-content-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> img {
		width: 24%;
		opacity: .5;
		filter: grayscale(.5); }

	> span {
		font-size: 24px;
		margin-top: 20px;
		color: rgba($colorDark, .38);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.u-link {
			font-size: 16px;
			line-height: 25px;
			margin-top: 10px; } } }
